import { LINKS } from '@/app/navigation';

const links = [
  { href: '', text: 'Мои оплаты' },
  { href: LINKS.TG, text: 'О проекте' },
  { href: LINKS.AGREEMENT, text: 'Пользовательское соглашение' },
  { href: LINKS.PRIVACY, text: 'Политика конфиденциальности' },
];

export const ProjectLinks = () => (
  <div className="text-neutral4 text-caption2 max-w-[336px]">
    {links.map(({ href, text }, index) => (
      <>
        {index !== 0 && ' • '}
        <a href={href} target="_blank" key={text} rel="noreferrer">
          {text}
        </a>
      </>
    ))}
    <span className="block">2024 © breed.show</span>
  </div>
);
