import { ReactNode, CSSProperties } from 'react';
import cn from 'classnames';

type TProps = {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  style?: CSSProperties;
};

export const Typography = ({
  customClassName,
  className,
  children,
  onClick,
  style,
}: TProps & { customClassName?: string }) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <div onClick={() => onClick && onClick()} style={style} className={cn(className, customClassName)}>
      {children}
    </div>
  );
};

export const Headline1 = ({ children, className, ...props }: TProps) => (
  <Typography customClassName={className} className="text-headline3 font-bold lg:text-headline1" {...props}>
    {children}
  </Typography>
);

export const Headline2 = ({ children, className, ...props }: TProps) => (
  <Typography customClassName={className} className="text-headline2 font-bold" {...props}>
    {children}
  </Typography>
);

export const Headline4 = ({ children, className, ...props }: TProps) => (
  <Typography customClassName={className} className="text-headline4 font-bold" {...props}>
    {children}
  </Typography>
);

export const Headline5 = ({ children, className, ...props }: TProps) => (
  <Typography customClassName={className} className="text-headline5 font-bold" {...props}>
    {children}
  </Typography>
);

export const Headline6 = ({ children, className, ...props }: TProps) => (
  <Typography customClassName={className} className="text-headline6 font-semibold" {...props}>
    {children}
  </Typography>
);

export const Headline3 = ({ children, className, ...props }: TProps) => (
  <Typography customClassName={className} className="text-headline3 font-bold" {...props}>
    {children}
  </Typography>
);

export const Body2 = ({ children, className, ...props }: TProps) => (
  <Typography customClassName={className} className="text-body2" {...props}>
    {children}
  </Typography>
);

export const Body3 = ({ children, className, ...props }: TProps) => (
  <Typography customClassName={className} className="text-body3" {...props}>
    {children}
  </Typography>
);

export const Hairline2 = ({ children, className, ...props }: TProps) => (
  <Typography customClassName={className} className="text-hairline2" {...props}>
    {children}
  </Typography>
);

export const Hairline3 = ({ children, className, ...props }: TProps) => (
  <Typography customClassName={className} className="text-hairline3" {...props}>
    {children}
  </Typography>
);

export const Caption2 = ({ children, className, ...props }: TProps) => (
  <Typography customClassName={className} className="text-caption2" {...props}>
    {children}
  </Typography>
);

export const Caption4 = ({ children, className, ...props }: TProps) => (
  <Typography customClassName={className} className="text-caption4" {...props}>
    {children}
  </Typography>
);
