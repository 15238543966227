import { convertDateToString } from '@/shared/utils';
import { TAnimalDto } from '@/entities/animal-controller';
import { Flex } from 'antd';
import { PseudoInput } from '@/shared/ui';

type TProps = {
  animal: TAnimalDto;
};

const NAME = 'Имя:';
const BREED = 'Порода:';
const SEX = 'Пол:';
const COLOR = 'Окрас:';
const DATE_BIRTH = 'Дата рождения:';
const BREEDER = 'Заводчик:';
const OWNER = 'Владелец:';
const ADDRESS = 'Адрес:';

export const AnimalPedigreeInfo = ({ animal }: TProps) => {
  const { breed, colors, speciesSex, dateBirth, breederName, ownerName, city, fullName, nursery } = animal;

  const color = colors.map(({ name }) => name).join(', ');
  const breedName = breed?.name || nursery.breeds[0].name;

  return (
    <Flex vertical gap={8}>
      <PseudoInput label={NAME} text={`${fullName} ${nursery.nameEn}`} />
      <PseudoInput label={BREED} text={breedName} />
      <PseudoInput label={SEX} text={speciesSex.labelSingular} />
      <PseudoInput label={COLOR} text={color} />
      <PseudoInput label={DATE_BIRTH} text={dateBirth && convertDateToString(dateBirth)} />
      <PseudoInput label={BREEDER} text={breederName} />
      <PseudoInput label={OWNER} text={ownerName} />
      <PseudoInput label={ADDRESS} text={city?.name} />
    </Flex>
  );
};
