import { ResetPasswordModal } from './ResetPasswordModal';
import { Input } from '@/shared/ui';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { emailRule } from '@/shared/validation';
import { TForgotPassword, useForgotPasswordMutation } from '@/entities/auth-controller';
import { Flex } from 'antd';
import { useResendPinTimer } from './hooks';

const TITLE = 'Восстановление пароля';
const SEND = 'Отправить';
const EMAIL = 'EMAIL';

type TProps = {
  onClose: () => void;
  onSubmit: (value: string) => void;
};

const FORM_FIELDS = {
  email: 'email',
} as const;

export const EmailStepModal = ({ onClose, onSubmit }: TProps) => {
  const { control, handleSubmit, setValue } = useForm<TForgotPassword>({
    resolver: zodResolver(
      z.object({
        [FORM_FIELDS.email]: emailRule,
      }),
    ),
  });

  const { timeInSeconds, ResendTimerJSX } = useResendPinTimer();

  const [sendPin, { isLoading }] = useForgotPasswordMutation();

  const onSubmitHandler = async (data: TForgotPassword) => {
    try {
      await sendPin(data).unwrap();
      onSubmit(data.email);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ResetPasswordModal
      title={TITLE}
      onCancel={onClose}
      okButtonProps={{
        children: SEND,
        loading: isLoading,
        onClick: handleSubmit(onSubmitHandler),
        disabled: !!timeInSeconds,
      }}
    >
      <Flex gap={24} vertical>
        <div>
          <p>Укажите свой e-mail, под которым</p>
          <p>Вы зарегистрированы, и на него будет отправлена</p>
          <p>информация о восстановлении пароля.</p>
        </div>
        <Input
          name={FORM_FIELDS.email}
          controllerProps={{ control, name: FORM_FIELDS.email }}
          labelProps={{ label: EMAIL, isHorizontal: false }}
          onChange={(e) => setValue(FORM_FIELDS.email, e.target.value.trim(), { shouldValidate: true })}
          trim
        />
        {!!timeInSeconds && <ResendTimerJSX />}
      </Flex>
    </ResetPasswordModal>
  );
};
