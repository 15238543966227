import { useState } from 'react';

import { PetsListSection } from '@/features/nursery';

import { useGetAnimalsQuery } from '@/entities/animal-controller';
import { TNurseryDto } from '@/entities/nursery-controller';
import { ANIMAL_SEX, ANIMAL_STATUS } from '@/entities/species-controller';
import { MODALS, setGlobalModal, useTypedDispatch } from '@/app/store';

import { comparisonNode, queries } from '@/shared/utils';
import { AnimalCrudPopup } from '@/widgets/animal/animal-crud-popup';
import { TAnimalSchema } from '@/widgets/animal/animal-crud-popup/hooks';

type TProps = {
  nursery: TNurseryDto;
};

type TCreateAnimalData = {
  animalSex: ANIMAL_SEX;
  status: ANIMAL_STATUS;
};

export const NurseryProducers = ({ nursery }: TProps) => {
  const {
    species,
    canEdit,
    account: { id },
  } = nursery;
  const dispatch = useTypedDispatch();

  const animalsNeuterQuery = queries.and([
    comparisonNode.equal('speciesSex.status.name', ANIMAL_STATUS.NEUTER),
    comparisonNode.equal('owner.id', id),
  ]);

  const animalsTribalQuery = queries.and([
    comparisonNode.equal('speciesSex.status.name', ANIMAL_STATUS.TRIBAL),
    comparisonNode.equal('owner.id', id),
  ]);

  const [animalCreateData, setAnimalCreateData] = useState<TCreateAnimalData | null>(null);

  const { data: animalsNeuter = [], refetch: neuterRefetch } = useGetAnimalsQuery({ query: animalsNeuterQuery });
  const { data: animalsTribal = [], refetch: tribalRefetch } = useGetAnimalsQuery({ query: animalsTribalQuery });

  const resetAnimalCreateData = () => setAnimalCreateData(null);

  const onOpenModalChange = (data: TCreateAnimalData) => {
    setAnimalCreateData(data);
  };

  const onSubmitFinish = (data: TAnimalSchema) => {
    resetAnimalCreateData();
    dispatch(setGlobalModal(MODALS.ADD_ANIMAL));

    data.status === ANIMAL_STATUS.NEUTER ? neuterRefetch() : tribalRefetch();
  };

  return (
    <>
      <PetsListSection
        canEdit={canEdit}
        data={[...animalsTribal, ...animalsNeuter]}
        speciesID={species.id}
        onOpenModal={onOpenModalChange}
      />
      {animalCreateData && (
        <AnimalCrudPopup
          defaultValues={{
            nursery: { value: nursery.id, label: nursery.nameEn },
            animalRegistrationSystemId: nursery.registrationSystem.id,
            breederName: nursery.account.name,
            breedId: nursery.breeds[0].id,
            status: animalCreateData.status,
            animalSex: animalCreateData.animalSex,
          }}
          onSubmitFinish={onSubmitFinish}
          open={!!animalCreateData}
          onCancel={resetAnimalCreateData}
          speciesId={species.id}
        />
      )}
    </>
  );
};
