import { Caption2 } from '@/shared/ui';
import { Flex } from 'antd';

export const ProjectRegistry = () => (
  <Flex className="text-neutral4 max-w-[420px]" vertical gap={2}>
    <Caption2>ООО “БРИД ШОУ”, УНП 193808267 (выдан Минским горисполкомом 05.11.2024, №0241932)</Caption2>
    <Caption2>Республика Беларусь, 220114, г. Минск, ул. Макаёнка, д. 12Г, пом. 257</Caption2>
    <Caption2>Режим работы: круглосуточно</Caption2>
  </Flex>
);
