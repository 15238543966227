import { Tabs, TTabsProps } from '@/shared/components';
import { SHOP_PATHS } from 'src/app/navigation/shop';
import { Outlet, useLocation } from 'react-router-dom';
import { FlexPage } from '@/shared/containers';

export const ShopPage = () => {
  const { pathname } = useLocation();

  const items: TTabsProps['items'] = [{ key: SHOP_PATHS.shop, label: 'Консоль' }];

  return (
    <FlexPage>
      <Tabs activeKey={pathname} items={items} />
      <Outlet />
    </FlexPage>
  );
};
