import { Loader, Plus } from '@/app/icons';
import { theme } from '@/app/theme';
import { Upload } from '@/shared/components';
import { Flex } from 'antd';
import { RcFile } from 'antd/es/upload';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { Image, TImageProps } from './Image';

type TProps = {
  images: TImageProps[];
  columns?: number;
  onUploadHandler?: (file: RcFile[]) => void;
  onAddBtnClick?: () => void;
  addIcon?: ReactNode;
  isLoading?: boolean;
};

export const Add = styled.div`
  .ant-upload {
    width: 100%;
    height: 100%;
    display: block;
  }

  cursor: pointer;
  border-radius: 8px;
  border: 1px dashed ${({ theme }) => theme.palette.neutral[5]};
  position: relative;
  aspect-ratio: 1/1;

  .add-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary[1]};

    svg {
      path {
        stroke: ${({ theme }) => theme.palette.primary[1]};
      }
    }
  }
`;

const List = styled(Flex)<{ $columns: number }>`
  width: 100%;
  .custom-image,
  .add-btn {
    width: calc((100% - 24px) / ${(props) => props.$columns});
  }
`;
export const Images = ({ columns = 3, images, onUploadHandler, onAddBtnClick, addIcon, isLoading }: TProps) => (
  <List $columns={columns} gap={12} wrap="wrap">
    {images.map((item) => (
      <Image key={item.src} {...item} />
    ))}
    {(!!onAddBtnClick || !!onUploadHandler) && (
      <Add className="add-btn" onClick={onAddBtnClick}>
        <Upload multiple openFileDialogOnClick={!!onUploadHandler} onUploadHandler={onUploadHandler}>
          <div className="add-content">
            {isLoading ? <Loader /> : addIcon || <Plus color={theme.palette.neutral[5]} width={48} height={48} />}
          </div>
        </Upload>
      </Add>
    )}
  </List>
);
