import { useLazyCheckEmailQuery } from '@/entities/auth-controller';
import { PasswordCreate } from '@/features/password';
import { Headline4 } from '@/shared/components';
import { ColumnWrapper } from '@/shared/containers';
import { EMAIL_EXIST_MESSAGE, emailRule } from '@/shared/validation';
import { FORM_FIELDS } from '@/widgets/registration/RegisterForm/constants';
import { useFormContext } from 'react-hook-form';
import { CREDENTIALS_TITLE, EMAIL } from './constants';
import { useMedia } from '@/shared/hooks/useMedia';
import { Input } from '@/shared/ui';

export const Credentials = () => {
  const { control, setError, clearErrors, setValue } = useFormContext();
  const [checkEmail] = useLazyCheckEmailQuery();

  const { isMobile } = useMedia();

  const onBlurEmail = async (email: string) => {
    try {
      const isEmailValid = emailRule.safeParse(email).success;
      if (isEmailValid) {
        const isEmailExist = await checkEmail(email).unwrap();
        isEmailExist ? setError(FORM_FIELDS.email, { message: EMAIL_EXIST_MESSAGE }) : clearErrors(FORM_FIELDS.email);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const width = isMobile ? 'w-full' : 'w-[48%]';

  return (
    <ColumnWrapper>
      <Headline4>{CREDENTIALS_TITLE}</Headline4>
      <Input
        name={FORM_FIELDS.email}
        className={width}
        onBlur={(e) => onBlurEmail(e.target.value)}
        onChange={(e) => setValue(FORM_FIELDS.email, e.target.value.trim(), { shouldValidate: true })}
        controllerProps={{ control, name: FORM_FIELDS.email }}
        labelProps={{
          label: EMAIL,
          isHorizontal: false,
          labelClassName: 'text-neutral5',
        }}
        trim
      />
      <PasswordCreate width={isMobile ? '100%' : '48%'} />
    </ColumnWrapper>
  );
};
