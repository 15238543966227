import { NotificationPopup } from '@/shared/components';

const TITLE = 'Карточка удалена';

type TProps = {
  onClose: () => void;
  text: string | null;
};

export const DeleteGoodsCardNotificationModal = ({ onClose, text }: TProps) => (
  <NotificationPopup open title={TITLE} onCancel={onClose}>
    Карточка <span className="text-headline6 font-semibold mx-2">{text}</span> удалена из каталога
  </NotificationPopup>
);
