import { SVGProps } from 'react';

export const ManagementClockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M26.1906 25.7999L21.024 22.7166C20.124 22.1833 19.3906 20.8999 19.3906 19.8499V13.0166"
      stroke="#FFD166"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66927 10.4997C4.58594 13.283 3.33594 16.7497 3.33594 20.4997C3.33594 29.6997 10.8026 37.1663 20.0026 37.1663C29.2026 37.1663 36.6693 29.6997 36.6693 20.4997C36.6693 11.2997 29.2026 3.83301 20.0026 3.83301C17.6193 3.83301 15.3359 4.33301 13.2859 5.24967"
      stroke="#FFD166"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
