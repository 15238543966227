/* eslint-disable @typescript-eslint/no-explicit-any */

import { Select, TSelectProps } from '@/shared/ui';

import { useDebounce } from '@/shared/hooks';
import { useCallback, useState } from 'react';
import { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';

export type TAsyncSelectValue = {
  value: any;
  label: string;
};

type TProps<OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType, ValueType = any> = TSelectProps<
  OptionType,
  ValueType
> & {
  useQuery: (search: string, page: number) => { data?: OptionType[]; isFetching: boolean };
};

export const AsyncSelect = <
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
  ValueType = any,
>({
  useQuery,
  ...props
}: TProps<OptionType, ValueType>) => {
  const [search, setSearch] = useState<string>('');

  const { data, isFetching } = useQuery(search, 1);

  const searchCallback = useCallback((search: string) => setSearch(search), []);

  const handleSearch = useDebounce(searchCallback, 500);

  return (
    <Select labelInValue options={data} loading={isFetching} filterOption={false} onSearch={handleSearch} {...props} />
  );
};
