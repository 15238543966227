import {
  TAnimalLitterRequestDto,
  useGetAnimalSexQuery,
  usePostAnimalLitterMutation,
} from '@/entities/animal-controller';
import { Divider } from '@/shared/components';
import { Input, Popup, Select, TPopupProps } from '@/shared/ui';
import { queries } from '@/shared/utils';
import { Flex } from 'antd';
import { FormProvider } from 'react-hook-form';

import { MODALS, setGlobalModal, useTypedDispatch } from '@/app/store';
import { SPECIES } from '@/entities/species-controller';
import { prepareColorsPayload, SpeciesColors } from '@/features/animal/species-colors';
import {
  LITTER_INDIVIDUAL_FORM_FIELDS,
  useLitterIndividualForm,
} from '@/features/litters/add-litter-individual-modal/model';

type TProps = TPopupProps & {
  speciesId: SPECIES;
  litter: number;
  nurseryId: number;
};

export const AddLitterIndividualModal = ({ speciesId, litter, nurseryId, ...props }: TProps) => {
  const formMethods = useLitterIndividualForm(speciesId);
  const { handleSubmit } = formMethods;

  const query = queries.equal('species.id', speciesId);

  const { data: sexOptions = [], isFetching: isSexFetching } = useGetAnimalSexQuery(query);

  const dispatch = useTypedDispatch();
  const [postAnimalLitter, { isLoading }] = usePostAnimalLitterMutation();

  const onFormSubmit = handleSubmit(async (data) => {
    const { fullName, animalSex } = data;
    try {
      const payload: TAnimalLitterRequestDto = {
        fullName,
        animalSex,
        colors: prepareColorsPayload(data),
        nurseryId,
        litter,
        speciesId,
      };
      await postAnimalLitter(payload).unwrap();
      props.onCancel();
      dispatch(setGlobalModal(MODALS.ADD_ANIMAL));
    } catch (e) {
      console.error(e);
    }
  });

  return (
    <Popup
      {...props}
      size="MEDIUM"
      title="Добавить особь"
      okText="Добавить"
      onOk={onFormSubmit}
      okButtonProps={{ loading: isLoading }}
      cancelText="Отменить"
    >
      <FormProvider {...formMethods}>
        <Flex vertical gap={14}>
          <Input
            controllerProps={{ name: LITTER_INDIVIDUAL_FORM_FIELDS.fullName }}
            labelProps={{ label: 'Имя питомца' }}
          />
          <Divider />
          <SpeciesColors speciesId={speciesId} />
          <Divider />
          <Select
            labelProps={{
              label: 'Пол',
            }}
            options={sexOptions}
            loading={isSexFetching}
            controllerProps={{ name: LITTER_INDIVIDUAL_FORM_FIELDS.animalSex }}
          />
        </Flex>
      </FormProvider>
    </Popup>
  );
};
