import { baseApi } from '@/app/core';

export type TPaymentRequestDto = {
  tariffId: number;
  currencyId: number;
};

export const paymentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    postTariffPayment: builder.mutation<string, TPaymentRequestDto>({
      query: (data) => ({
        url: 'payment',
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const { usePostTariffPaymentMutation } = paymentApi;
