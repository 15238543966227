import { z } from 'zod';
import { emailRule, selectRule, stringRule } from '@/shared/validation';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { SPECIES } from '@/entities/species-controller';
import { GOODS_CATEGORY } from '@/entities/good-category-controller';
import { MODALS, setGlobalModal, useTypedDispatch } from '@/app/store';
import {
  postMarketplaceList,
  TGoodDto,
  updateShowRoomCache,
  usePostGoodsMutation,
  usePutGoodsMutation,
} from '@/entities/goods-controller';
import { animalApi } from '@/entities/animal-controller';
import { useParams } from 'react-router-dom';

export const SHOWROOM_FORM_FIELDS = {
  phone: 'phone',
  animal: 'animal',
  price: 'price',
  currency: 'currency',
  email: 'email',
  country: 'country',
  city: 'city',
  delivery: 'delivery',
} as const;

const schema = z.object({
  [SHOWROOM_FORM_FIELDS.phone]: z
    .object({
      value: z.string(),
      isValid: z.boolean(),
    })
    .refine(({ isValid }) => isValid, {
      path: [SHOWROOM_FORM_FIELDS.phone],
    }),
  [SHOWROOM_FORM_FIELDS.email]: emailRule,
  [SHOWROOM_FORM_FIELDS.animal]: stringRule('Необходимо выбрать питомца'),
  [SHOWROOM_FORM_FIELDS.country]: selectRule(),
  [SHOWROOM_FORM_FIELDS.city]: selectRule(),
  [SHOWROOM_FORM_FIELDS.delivery]: z.boolean(),
  [SHOWROOM_FORM_FIELDS.price]: stringRule(),
  [SHOWROOM_FORM_FIELDS.currency]: selectRule(),
});

export type TAddGoodsSchema = z.infer<typeof schema>;

export const useShowroomForm = ({
  defaultValues,
  speciesId,
  accountId,
  onClose,
  data,
}: {
  defaultValues?: Partial<TAddGoodsSchema>;
  speciesId: SPECIES;
  accountId: string;
  onClose: () => void;
  data?: TGoodDto;
}) => {
  const { catalogId } = useParams();
  const dispatch = useTypedDispatch();
  const [publish, { isLoading: isCreating }] = usePostGoodsMutation();
  const [updateGoods, { isLoading: isUpdating }] = usePutGoodsMutation();

  const formMethods = useForm<TAddGoodsSchema>({
    defaultValues: {
      [SHOWROOM_FORM_FIELDS.delivery]: false,
      [SHOWROOM_FORM_FIELDS.currency]: 1,
      ...defaultValues,
    },
    resolver: zodResolver(schema),
  });

  const { handleSubmit } = formMethods;

  const onCreate = async (goodsData: TAddGoodsSchema) => {
    try {
      const price = parseFloat(goodsData.price.replace(',', '.'));
      const category = speciesId === SPECIES.CATS ? GOODS_CATEGORY.CATS : GOODS_CATEGORY.DOGS;
      const phone = goodsData.phone.value;
      const payload = { ...goodsData, phone, category, price };

      const response = await publish(payload).unwrap();

      dispatch(
        setGlobalModal({
          modal: MODALS.ADD_PETCARD_NOTIFICATION,
          text: `${response.animal.fullName} ${response.account.name}`,
        }),
      );

      dispatch(updateShowRoomCache(response, String(accountId)));
      if (category === Number(catalogId) || !catalogId) {
        dispatch(postMarketplaceList(response, catalogId));
      }
      dispatch(animalApi.util.invalidateTags([{ type: 'ANIMAL', id: goodsData.animal }]));
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  const onUpdate = async (goodsData: TAddGoodsSchema) => {
    const price = parseFloat(goodsData.price.replace(',', '.'));
    const phone = goodsData.phone.value;
    const category = data?.category.id;
    const id = data?.id;

    const payload = { ...goodsData, phone, category, price, id };

    try {
      await updateGoods(payload).unwrap();
      dispatch(setGlobalModal(MODALS.EDIT_GOODS_NOTIFICATION));
      onClose();
    } catch (e) {
      console.error('Failed to save changes:', e);
    }
  };

  const onFormSubmit = handleSubmit((formData) => (data ? onUpdate(formData) : onCreate(formData)));

  return { isLoading: isUpdating || isCreating, onFormSubmit, formMethods };
};
