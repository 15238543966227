import { CONGRATS } from '@/features/modals/Notification/constants';
import { NotificationPopup } from '@/shared/components';
import { Body2, Headline6 } from '@/shared/ui';

type TProps = {
  onClose: () => void;
  text: string | null;
};

const TITLE = 'Товар опубликован';

export const AddPetCardNotificationModal = ({ onClose, text }: TProps) => {
  return (
    <NotificationPopup open title={TITLE} onCancel={onClose}>
      <Headline6>{CONGRATS}</Headline6>
      <Body2>
        Карточка питомца
        <span className="text-headline6 font-semibold mx-2">{text}</span>
        успешно опубликована для продажи.
      </Body2>
    </NotificationPopup>
  );
};
