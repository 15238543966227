import { AsyncSelect, Body2, Caption2, RadioGroup, Select, TSelectProps } from '@/shared/ui';
import { Flex } from 'antd';
import { comparisonNode, queries } from '@/shared/utils';
import { ANIMAL_SEX } from '@/entities/species-controller';
import { TAnimalDto, useGetAnimalsQuery } from '@/entities/animal-controller';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { LITTER_FORM_FIELDS, TLitterSchema } from '@/widgets/litters';
import Avatar from 'react-avatar';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { convertMotherFatherResponse } from '@/features/litters/select/helpers';

export enum FATHER_ENUM {
  INTERNAL,
  EXTERNAL,
}

const fatherOptions = [
  {
    value: FATHER_ENUM.INTERNAL,
    label: 'Свой самец',
  },
  {
    value: FATHER_ENUM.EXTERNAL,
    label: 'Чужой самец',
  },
];

export const LittersFatherSelect = ({
  accountId,
  handleChangeParent,
  ...props
}: TSelectProps<TAnimalDto & DefaultOptionType> & { accountId: string; handleChangeParent: () => void }) => {
  const {
    setValue,
    formState: { defaultValues },
  } = useFormContext<TLitterSchema>();

  const useGetExternalFathers = (search: string) =>
    useGetAnimalsQuery(
      {
        query: queries.and([
          comparisonNode.partialEqual('fullName', search),
          comparisonNode.equal('speciesSex.sex', ANIMAL_SEX.MALE),
        ]),
      },
      {
        selectFromResult: ({ data, ...rest }) => ({
          data: convertMotherFatherResponse(data, defaultValues?.birthDate)?.map(({ label, ...item }) => ({
            ...item,
            label,
            option: (
              <Flex gap={8} align="center">
                <Avatar size="36px" round src={item.images[0]?.url} name={label} />
                <Flex vertical>
                  <Body2>{label}</Body2>
                  <Caption2 className="text-neutral4">{item.breederName}</Caption2>
                </Flex>
              </Flex>
            ),
          })),
          ...rest,
        }),
      },
    );

  const [fatherType, setFatherType] = useState<FATHER_ENUM>(FATHER_ENUM.INTERNAL);
  const isInternal = fatherType === FATHER_ENUM.INTERNAL;

  const { data, isFetching } = useGetAnimalsQuery(
    {
      query: queries.and([
        comparisonNode.equal('owner.id', `${accountId}`),
        comparisonNode.equal('speciesSex.sex', ANIMAL_SEX.MALE),
      ]),
    },
    {
      selectFromResult: ({ data, ...rest }) => ({
        data: convertMotherFatherResponse(data, defaultValues?.birthDate),
        ...rest,
      }),
    },
  );

  const handleChangeType = (value: FATHER_ENUM) => {
    setFatherType(value);
    setValue(LITTER_FORM_FIELDS.father, null);
  };

  const onChange = (_: string, option: TAnimalDto | TAnimalDto[]) => {
    if (!Array.isArray(option)) {
      setValue(LITTER_FORM_FIELDS.father, option, { shouldValidate: true });
      handleChangeParent();
    }
  };

  return (
    <Flex vertical gap={24} className="w-full">
      <RadioGroup options={fatherOptions} value={fatherType} onChange={(e) => handleChangeType(e.target.value)} />
      {isInternal ? (
        <Select onChange={onChange} options={data} loading={isFetching} {...props} />
      ) : (
        <AsyncSelect
          fieldNames={{ label: 'option' }}
          labelRender={({ label }) => label}
          onChange={onChange}
          useQuery={useGetExternalFathers}
          showSearch={!isInternal}
          {...props}
        />
      )}
    </Flex>
  );
};
