import { LITTERS_PATHS } from '@/app/navigation';
import { TLitterDto } from '@/entities/litters-controller/types';
import { Body2, Headline1 } from '@/shared/components/Typography';
import { Card, Image } from '@/shared/ui';
import { Flex } from 'antd';
import { useNavigate } from 'react-router-dom';
import { convertDateToString } from '@/shared/utils';
import { AnimalAvatar } from '@/app/icons/AnimalAvatar';
import { useMedia } from '@/shared/hooks';

type TProps = {
  litter: Partial<TLitterDto>;
  isList: boolean;
};

export const LitterCard = ({ litter, isList }: TProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${LITTERS_PATHS.litters}/${litter.id}`);
  };

  const litterImage = litter.images?.[0]?.url;
  const { isMobile } = useMedia();

  return isList ? (
    <Card
      className="cursor-pointer"
      onClick={handleClick}
      metaProps={{
        description: (
          <Flex vertical={!isList} justify="space-between" align={isList ? 'center' : 'start'} gap={8} className="px-2">
            <Headline1>{litter.name}</Headline1>
            {isList && litter.awaiting && <Body2>Ожидаем</Body2>}
            <Body2>
              <strong>Мать: </strong>
              {litter?.mother?.fullName || '-'}
            </Body2>
            <Body2>
              <strong>Отец: </strong>
              {litter?.father?.fullName || '-'}
            </Body2>
            {!isList && <Body2>{litter.birthDate && convertDateToString(litter.birthDate)}</Body2>}
          </Flex>
        ),
      }}
    />
  ) : (
    <Flex className="cursor-pointer" onClick={handleClick}>
      <Flex className="absolute w-[35px] h-[34px]">
        <Headline1 className="flex justify-center bg-[#FBFCFE] rounded-br-[8px] z-[1] w-full">{litter.name}</Headline1>
      </Flex>
      {litterImage ? (
        <Image src={litterImage} height={isMobile ? 156 : 169} />
      ) : (
        <AnimalAvatar height={isMobile ? 156 : 169} className="rounded" />
      )}
    </Flex>
  );
};
