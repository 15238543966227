import { Add, Headline4, Image, Images, Popup, Upload } from '@/shared/components';
import { TImageInfoDto } from '@/entities/file-controller';
import { theme } from '@/app/theme';
import { useModal } from '@/shared/hooks';
import { useUploadAnimalImages } from '@/features/animal';
import { AnimalAvatar } from '@/app/icons/AnimalAvatar';
import { Flex, Image as AntImage } from 'antd';
import styles from './styles.module.scss';
import { Loader, Plus } from '@/app/icons';
import { RcFile } from 'antd/es/upload';
import { ImageElementProps } from 'rc-image/lib/interface';

type TProps = {
  images: TImageInfoDto[] & ImageElementProps;
  animalId: string;
  canEdit: boolean;
  onUploadHandler?: (file: RcFile[]) => void;
};

export const AnimalImagesList = ({ images, animalId, canEdit, onUploadHandler }: TProps) => {
  const showImages = images.slice(0, 5);
  const restImagesLength = images.length - showImages.length;
  const { upload, isLoading } = useUploadAnimalImages();

  const { isOpen, close, open } = useModal();

  const addIcon = <Headline4 styles={{ color: theme.palette.neutral[6] }}>+{restImagesLength}</Headline4>;

  const onAddBtnClick = images.length - showImages.length && canEdit ? open : undefined;

  return (
    <>
      {images.length ? (
        <Flex className={styles['images-wrapper']}>
          <AntImage.PreviewGroup preview={{ rootClassName: styles['custom-image-preview'] }}>
            {images.map((el) => (
              <Image key={el.id} w-full src={el.url} preview />
            ))}
            {(!!onAddBtnClick || !!onUploadHandler) && (
              <Add className="add-btn" onClick={onAddBtnClick}>
                <Upload multiple openFileDialogOnClick={!!onUploadHandler} onUploadHandler={onUploadHandler}>
                  <div className="add-content">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      addIcon || <Plus color={theme.palette.neutral[5]} width={48} height={48} />
                    )}
                  </div>
                </Upload>
              </Add>
            )}
          </AntImage.PreviewGroup>
        </Flex>
      ) : (
        <AnimalAvatar />
      )}
      {isOpen && (
        <Popup title="Медиа" open maskClosable onCancel={close} withFooterDivider={false}>
          <Images
            images={images.map(({ url }) => ({ src: url, scale: false }))}
            onUploadHandler={(file) => upload(animalId, file)}
            isLoading={isLoading}
          />
        </Popup>
      )}
    </>
  );
};
