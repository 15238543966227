import { Input, Tabs, TTabsProps } from '@/shared/components';

import { NurseryProducers } from '@/widgets/nursery';

import { Loader } from '@/app/icons';
import { NURSERY_TABS, SEARCH_PARAMS } from '@/app/navigation';
import { FlexPage } from '@/shared/containers';
import { useSearchParams } from 'react-router-dom';
import { Litters } from './litters';
import { TNurseryDto } from '@/entities/nursery-controller';
import { useGetCurrentUserQuery } from '@/entities/user-controller';

const NURSERY_SELECT_LABEL = 'Питомник';

export const NurseryPage = ({ nursery }: { nursery?: TNurseryDto }) => {
  const { data: currentUser } = useGetCurrentUserQuery(undefined, { skip: !!nursery });
  const selectedNursery = nursery || currentUser?.nurseries[0];

  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || undefined;

  const onTabClick = (route: string) => {
    setSearchParams({ [SEARCH_PARAMS.TAB]: route });
  };

  if (!selectedNursery) {
    return <Loader />;
  }

  const items: TTabsProps['items'] = [
    {
      key: NURSERY_TABS.PRODUCERS,
      label: 'Производители',
      children: <NurseryProducers nursery={selectedNursery} />,
    },
    {
      key: NURSERY_TABS.LITTERS,
      label: 'Помёты',
      children: <Litters nurseryId={selectedNursery.id} />,
    },
  ];

  return (
    <FlexPage>
      <Input disabled value={selectedNursery.nameEn} labelProps={{ label: NURSERY_SELECT_LABEL }} />
      <Tabs onTabClick={onTabClick} activeKey={activeTab} destroyInactiveTabPane items={items} />
    </FlexPage>
  );
};
