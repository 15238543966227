import { Body2 } from '@/shared/ui';
import { Flex } from 'antd';
import { ReactNode } from 'react';
import cn from 'classnames';
import { useMedia } from '@/shared/hooks';
import { Warning } from '@/app/icons';

export type TLabelProps = {
  label?: string | ReactNode;
  subtitle?: string;
  isHorizontal?: boolean;
  labelWrapperClassName?: string;
  labelClassName?: string;
  contentClassName?: string;
};

export const Label = ({
  label,
  labelClassName,
}: {
  label: TLabelProps['label'];
  labelClassName: TLabelProps['labelClassName'];
}) => {
  if (typeof label !== 'string') {
    return label;
  }

  return <Body2 className={cn('label w-full font-semibold', labelClassName)}>{label}</Body2>;
};

export const LabelWrapper = ({
  children,
  isHorizontal = true,
  label,
  subtitle,
  labelWrapperClassName,
  labelClassName,
  contentClassName,
}: TLabelProps & { children: ReactNode }) => {
  const { isDesktop } = useMedia();

  const isVertical = !isDesktop || !isHorizontal;

  return (
    <Flex
      vertical={isVertical}
      justify={isVertical ? undefined : 'space-between'}
      gap={isVertical ? 8 : 12}
      className={cn({ 'label-wrapper': label }, 'w-full', labelWrapperClassName)}
    >
      {label && <Label labelClassName={labelClassName} label={label} />}
      {subtitle && (
        <Body2 className="flex items-center gap-xxs text-neutral4">
          <Warning className="min-h-[16px] min-w-[16px]" />
          {subtitle}
        </Body2>
      )}
      <div className={cn('w-full content-wrapper', contentClassName)}>{children}</div>
    </Flex>
  );
};
