import { Flex, Progress } from 'antd';
import { Body2, Body3, Headline2, Headline4, Headline6 } from '@/shared/ui';
import { ManagementCheckedIcon, ManagementClockIcon } from '@/app/icons';
import cn from 'classnames';
import { Divider } from '@/shared/components';
import { queries } from '@/shared/utils';
import { selectCurrentAccountId, useTypedSelector } from '@/app/store';
import { useGetGoodsQuery } from '@/entities/goods-controller';

const Icon = ({ status }: { status: 'done' | 'development' | 'pending' }) => (
  <Flex vertical align="center" className="w-[64px]">
    {status === 'development' ? (
      <>
        <ManagementClockIcon className="min-w-[40px] min-h-[40px]" />
        <div className="font-[500] text-[9px] leading-[11px] text-[#EFAF1A]">В разработке</div>
      </>
    ) : (
      <ManagementCheckedIcon
        className={cn('min-w-[40px] min-h-[40px]', {
          'text-primary4': status === 'done',
          'text-neutral6': status === 'pending',
        })}
      />
    )}
  </Flex>
);

export const DevelopmentSteps = () => {
  const accountId = useTypedSelector(selectCurrentAccountId);
  const query = queries.equal('account.id', String(accountId));
  const { data } = useGetGoodsQuery({ query });

  const items: { status: 'done' | 'development' | 'pending'; title: string; description: string }[] = [
    {
      title: 'Добавить новый товар',
      description:
        'Ваши клиенты ждут! Чем больше товаров, тем больше шансов быть замеченным. Добавьте хотя бы один новый товар уже сегодня',
      status: data?.totalElements ? 'done' : 'pending',
    },
    {
      title: 'Улучшить фотографии',
      description:
        'Качественные изображения повышают доверие. Загрузите новые фото, чтобы ваши товары выглядели лучше!',
      status: 'pending',
    },
    {
      title: 'Применить специальные предложения и скидки',
      description:
        'Привлекайте покупателей, создавая временные скидки и акции на ваши товары. Специальные предложения помогают стимулировать спрос и выделяют ваш магазин среди конкурентов',
      status: 'pending',
    },
    {
      title: 'Улучшить SEO товаров',
      description:
        'Повышайте видимость вашего товара в поисковых системах, добавив ключевые слова, точные описания и уникальные заголовки. Это поможет вам привлечь больше клиентов из органического поиска',
      status: 'development',
    },
    {
      title: 'Настроить рекламные кампании',
      description:
        'Привлекайте больше покупателей! Настройте рекламу и делитесь своими товарами в соцсетях и на других платформах',
      status: 'development',
    },
    {
      title: 'Создать свой бренд',
      description:
        'Сформируйте уникальный образ вашего магазина, добавив уникальные атрибуты и описание. Это поможет вашим товарам выделяться среди конкурентов и повысит узнаваемость на маркетплейсе',
      status: 'development',
    },
    {
      title: 'Настроить лендинг с актуальными товарами',
      description:
        'Cоздайте блок актуальных товаров на лендинговую страницу вашего персонального сайта, чтобы товарами можно было легко управлять и показывать только актуальную информацию',
      status: 'development',
    },
  ];

  const percent = data?.totalElements ? 14 : 0;

  return (
    <Flex vertical className="p-md border border-neutral6 rounded-xs" gap={24}>
      <Headline2>Готовы развивать свой магазин?</Headline2>
      <Body2 className="mt-xs">
        Ваш магазин уже запущен, и это только начало! Используйте наш чек-лист для успешного роста
      </Body2>
      <Flex vertical>
        <Progress percent={percent} showInfo={false} status="success" size="small" />
        <Body3 className="text-neutral4">
          Ваш магазин готов на <span className="font-semibold text-body2">{percent}%</span>
        </Body3>
        <Body3 className="text-neutral4">Завершите настройку, чтобы получить максимум от продаж</Body3>
      </Flex>
      <Flex vertical>
        {items.map(({ title, status, description }, index) => (
          <>
            {index !== 0 && <Divider />}
            <Flex key={title} align="center" gap={16} className="py-[12px]">
              <Icon status={status} />
              <Flex vertical className="flex-1">
                <Headline6 className={cn({ 'text-neutral4': status === 'done' })}>{title}</Headline6>
                <Body2 className={cn({ 'text-neutral4': status === 'done' })}>{description}</Body2>
              </Flex>
            </Flex>
          </>
        ))}
      </Flex>
      <Headline4 className="mx-auto">Ваш магазин - Ваши правила!</Headline4>
      <Body2>Следуйте этим шагам, и вы увидите, как растет интерес к вашим товарам и увеличиваются продажи!</Body2>
    </Flex>
  );
};
