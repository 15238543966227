import { useGetBreedsQuery } from '@/entities/breed-controller';
import { queries } from '@/shared/utils';
import { SPECIES } from '@/entities/species-controller';
import { Select, TSelectProps } from '@/shared/ui';

export const BreedsSelect = ({
  speciesId,
  ...props
}: TSelectProps & {
  speciesId: SPECIES;
}) => {
  const speciesQuery = queries.equal('species.id', speciesId);

  const { data: breedOptions, isFetching: isBreedsFetching } = useGetBreedsQuery({ query: speciesQuery });

  return <Select options={breedOptions} loading={isBreedsFetching} labelProps={{ label: 'Порода' }} {...props} />;
};
