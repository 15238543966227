import { SVGProps } from 'react';

export const Vkontakte = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="6" cy="6" r="5.7" stroke="#8A91A9" strokeWidth="0.6" />
    <path
      d="M6.35301 8.47383C3.72435 8.47383 2.22555 6.67527 2.16406 3.67767H3.48608C3.52835 5.87591 4.49681 6.80593 5.26542 6.99809V3.67767H6.50674V5.57231C7.26382 5.4916 8.06318 4.62691 8.3322 3.67383H9.56967C9.46877 4.16719 9.26721 4.63444 8.97759 5.04639C8.68796 5.45835 8.3165 5.80615 7.8864 6.06806C8.36638 6.30696 8.79025 6.64489 9.13008 7.05957C9.46991 7.47426 9.71797 7.95627 9.8579 8.47383H8.49361C8.20153 7.56302 7.47135 6.85589 6.50674 6.75982V8.47383H6.35686H6.35301Z"
      fill="currentColor"
    />
  </svg>
);
