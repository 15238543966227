import * as z from 'zod';
import {
  PASSWORD_MAX_LENGTH,
  PASSWORD_MAX_MESSAGE,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MIN_MESSAGE,
} from '@/shared/validation';

export const LIMIT_SYMBOLS = 'от 8 до 16 символов';
export const CAPITAL_LETTER = 'большая буква';
export const LOWER_LETTER = 'маленькая буква';
export const DIGITS = 'цифра';
export const SYMBOLS = 'спецсимвол';

const PASSWORD_DIGIT_REGEX = /\d/;
const PASSWORD_SYMBOL_REGEX = /[`!@#$%^&*()_+\-=№[\]{};:'",.<>/?\\|°~]/;
const PASSWORD_CAPITAL_LETTER = /(?=.*[A-Z])/;
const PASSWORD_LOWER_LETTER = /(?=.*[a-z])/;

export const passwordMinMaxRule = z
  .string()
  .min(PASSWORD_MIN_LENGTH, PASSWORD_MIN_MESSAGE)
  .max(PASSWORD_MAX_LENGTH, PASSWORD_MAX_MESSAGE);
export const passwordDigitRule = z.string().regex(PASSWORD_DIGIT_REGEX);
export const passwordSymbolRule = z.string().regex(PASSWORD_SYMBOL_REGEX);
export const passwordCapitalLetterRule = z.string().regex(PASSWORD_CAPITAL_LETTER);
export const passwordLowerLetterRule = z.string().regex(PASSWORD_LOWER_LETTER);
