import { SVGProps } from 'react';

export const InfoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 1.4375C4.82864 1.4375 1.4375 4.82864 1.4375 9C1.4375 13.1714 4.82864 16.5625 9 16.5625C13.1714 16.5625 16.5625 13.1714 16.5625 9C16.5625 4.82864 13.1714 1.4375 9 1.4375ZM9 16.4375C4.89886 16.4375 1.5625 13.1011 1.5625 9C1.5625 4.89886 4.89886 1.5625 9 1.5625C13.1011 1.5625 16.4375 4.89886 16.4375 9C16.4375 13.1011 13.1011 16.4375 9 16.4375Z"
        fill="currentFill"
        stroke="currentColor"
      />
      <path
        d="M9 8.1875C8.98694 8.1875 8.97128 8.1927 8.95699 8.20699C8.9427 8.22128 8.9375 8.23694 8.9375 8.25V12C8.9375 12.0131 8.9427 12.0287 8.95699 12.043C8.97128 12.0573 8.98694 12.0625 9 12.0625C9.01306 12.0625 9.02872 12.0573 9.04301 12.043C9.0573 12.0287 9.0625 12.0131 9.0625 12V8.25C9.0625 8.23694 9.0573 8.22128 9.04301 8.20699C9.02872 8.1927 9.01306 8.1875 9 8.1875Z"
        fill="currentFill"
        stroke="currentColor"
      />
      <path
        d="M8.90731 5.7715L8.90731 5.7715C8.87778 5.78381 8.84913 5.80046 8.82078 5.82315C8.79485 5.85444 8.78448 5.87417 8.77667 5.89446L8.77685 5.89452L8.77154 5.90727C8.75535 5.94613 8.75 5.97832 8.75 5.99997C8.75 6.02161 8.75535 6.0538 8.77154 6.09266C8.78365 6.12172 8.79998 6.14993 8.82212 6.17785C8.85003 6.19999 8.87825 6.21632 8.90731 6.22843C8.96423 6.25215 9.03577 6.25215 9.09269 6.22843C9.12175 6.21632 9.14997 6.19999 9.17788 6.17785C9.20002 6.14993 9.21635 6.12172 9.22846 6.09266C9.24465 6.0538 9.25 6.02161 9.25 5.99997C9.25 5.97832 9.24465 5.94613 9.22846 5.90727L9.22829 5.90735L9.22333 5.89446C9.21552 5.87417 9.20515 5.85444 9.17922 5.82315C9.15087 5.80046 9.12222 5.78381 9.09269 5.7715L9.09269 5.7715C9.05384 5.75531 9.02165 5.74997 9 5.74997C8.97835 5.74997 8.94616 5.75531 8.90731 5.7715Z"
        fill="currentFill"
        stroke="currentColor"
      />
    </svg>
  );
};
