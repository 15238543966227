import { useRefreshMutation } from '@/entities/auth-controller';
import { useEffect } from 'react';
import { getStorageItem, setStorageItem, StorageKeys } from '@/shared/utils';
import { HOME_PATHS } from '@/app/navigation';
import { Headline2 } from '@/shared/ui';

export const PaymentDone = () => {
  const [refreshTokens] = useRefreshMutation();

  const goHome = () => {
    window.location.href = HOME_PATHS.home;
  };

  useEffect(() => {
    const refresh = async () => {
      try {
        const token = getStorageItem(StorageKeys.ACCESS_TOKEN);
        const refreshToken = getStorageItem(StorageKeys.REFRESH_TOKEN);

        if (!token || !refreshToken) {
          return goHome();
        }
        const response = await refreshTokens({ token, refreshToken }).unwrap();
        setStorageItem([
          { key: StorageKeys.ACCESS_TOKEN, value: response.token },
          { key: StorageKeys.REFRESH_TOKEN, value: response.refreshToken },
        ]);
      } finally {
        goHome();
      }
    };

    refresh();
  }, [refreshTokens]);

  return <Headline2>Подтверждение оплаты...</Headline2>;
};
