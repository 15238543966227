import { AnyZodObject, z } from 'zod';
import {
  DATE_VALIDATION_MESSAGE,
  NAME_ANIMAL_VALIDATION_MESSAGE,
  nameRule,
  nativeEnumRule,
  selectRule,
  stringRule,
} from '@/shared/validation';
import { TAsyncSelectValue } from '@/shared/ui';
import { ANIMAL_SEX, ANIMAL_STATUS, SPECIES } from '@/entities/species-controller';
import { TAnimalRequestDto, usePostAnimalMutation } from '@/entities/animal-controller';
import { prepareColorsPayload, useColorsSchema } from '@/features/animal/species-colors';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

export const ANIMAL_FORM_FIELDS = {
  images: 'images',
  animalRegistrationSystemId: 'animalRegistrationSystemId',
  isShowChipId: 'isShowChipId',
  chipId: 'chipId',
  isShowStampId: 'isShowStampId',
  stampId: 'stampId',
  nursery: 'nursery',
  breederName: 'breederName',
  breedId: 'breedId',
  fullName: 'fullName',
  dateBirth: 'dateBirth',
  animalSex: 'animalSex',
  isShowDeadDate: 'isShowDeadDate',
  dateDead: 'dateDead',
  status: 'status',
  fatherName: 'fatherName',
  fatherNurseryId: 'fatherNurseryId',
  motherName: 'motherName',
  motherNurseryId: 'motherNurseryId',
  birthCountryId: 'birthCountryId',
  liveCountryId: 'liveCountryId',
  cityId: 'cityId',
  pedigreeId: 'pedigreeId',
  hashTags: 'hashTags',
} as const;

export const animalSchema = z.object({
  [ANIMAL_FORM_FIELDS.images]: z
    .object({
      id: z.string(),
      url: z.string(),
    })
    .array(),
  [ANIMAL_FORM_FIELDS.animalRegistrationSystemId]: selectRule(),
  [ANIMAL_FORM_FIELDS.isShowChipId]: z.boolean().optional(),
  [ANIMAL_FORM_FIELDS.chipId]: z.string().optional(),
  [ANIMAL_FORM_FIELDS.isShowStampId]: z.boolean().optional(),
  [ANIMAL_FORM_FIELDS.stampId]: z.string().optional(),
  [ANIMAL_FORM_FIELDS.breederName]: nameRule(),
  [ANIMAL_FORM_FIELDS.nursery]: z
    .custom<TAsyncSelectValue>()
    .nullable()
    .refine((item) => !!item),
  [ANIMAL_FORM_FIELDS.fullName]: nameRule(NAME_ANIMAL_VALIDATION_MESSAGE),
  [ANIMAL_FORM_FIELDS.breedId]: selectRule(),
  [ANIMAL_FORM_FIELDS.dateBirth]: stringRule(DATE_VALIDATION_MESSAGE),
  [ANIMAL_FORM_FIELDS.animalSex]: nativeEnumRule(ANIMAL_SEX),
  [ANIMAL_FORM_FIELDS.isShowDeadDate]: z.boolean().optional(),
  [ANIMAL_FORM_FIELDS.dateDead]: z.string().optional(),
  [ANIMAL_FORM_FIELDS.status]: nativeEnumRule(ANIMAL_STATUS),
  [ANIMAL_FORM_FIELDS.birthCountryId]: selectRule(),
  [ANIMAL_FORM_FIELDS.liveCountryId]: selectRule(),
  [ANIMAL_FORM_FIELDS.cityId]: selectRule(),
  [ANIMAL_FORM_FIELDS.pedigreeId]: z
    .object({
      id: z.string(),
      url: z.string(),
    })
    .array()
    .optional(),
  [ANIMAL_FORM_FIELDS.hashTags]: z.string().array().optional(),
});

export type TAnimalSchema = z.infer<typeof animalSchema>;

export type TAnimalFormProps = {
  defaultValues?: Partial<TAnimalSchema>;
  onSubmitFinish?: (data: TAnimalSchema & { id: string }) => void;
  speciesId: SPECIES;
  schema?: AnyZodObject;
};

export const useAnimalForm = ({ onSubmitFinish, speciesId, defaultValues, schema }: TAnimalFormProps) => {
  const colorsSchema = useColorsSchema(speciesId);

  const mergedAnimalSchema = schema || animalSchema;

  const formMethods = useForm<TAnimalSchema>({
    defaultValues: {
      [ANIMAL_FORM_FIELDS.images]: [],
      ...defaultValues,
    },
    resolver: zodResolver(mergedAnimalSchema.and(colorsSchema)),
  });
  const { handleSubmit } = formMethods;
  const [postAnimal, { isLoading }] = usePostAnimalMutation();

  const onFormSubmit = handleSubmit(async (data) => {
    const { pedigreeId, images, nursery } = data;

    if (!nursery) {
      return;
    }
    const payload: TAnimalRequestDto = {
      ...data,
      speciesId,
      nurseryId: nursery.value,
      colors: prepareColorsPayload(data),
      images: images.map(({ id }) => id),
      pedigree: pedigreeId && pedigreeId[0]?.id,
    };

    const { id } = await postAnimal(payload).unwrap();

    if (onSubmitFinish) {
      onSubmitFinish({ ...data, id });
    }
  });

  return { formMethods, onFormSubmit, isLoading };
};
