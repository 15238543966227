import { MenuProps } from 'antd';
import { Dropdown } from '@/shared/ui';
import { useState } from 'react';
import { AddShowroomProductModal, DeleteProductCardModal } from '@/widgets/show-room';
import { TGoodDtoExt } from '@/entities/goods-controller';
import { priceConverter } from '@/shared/utils';

enum ACTIONS {
  EDIT,
  DELETE,
}

type TProps = {
  data: TGoodDtoExt;
};

export const GoodsCrudDropdown = ({ data }: TProps) => {
  const [actions, setActions] = useState<ACTIONS | null>(null);
  const resetActions = () => setActions(null);

  const isEditMode = ACTIONS.EDIT === actions;
  const isOpenDelete = ACTIONS.DELETE === actions;

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: 'Редактировать',
      onClick: () => setActions(ACTIONS.EDIT),
    },
    {
      key: 'delete',
      label: 'Удалить из каталога ',
      onClick: () => setActions(ACTIONS.DELETE),
    },
  ];

  const defaultValues = {
    animal: data?.animal.id,
    phone: { value: data?.phone as string, isValid: true },
    email: data?.email,
    price: priceConverter(data?.price as number),
    currency: data?.currency?.id,
    country: data?.country?.id,
    city: data?.city?.id,
    delivery: data?.delivery,
  };

  return (
    <>
      <Dropdown menu={{ items }} placement="bottomLeft" />
      {isEditMode && (
        <AddShowroomProductModal
          speciesId={data.animal.nursery.species.id}
          data={data}
          onCancel={resetActions}
          open={isEditMode}
          defaultValues={defaultValues}
        />
      )}
      {isOpenDelete && (
        <DeleteProductCardModal
          onClose={resetActions}
          id={data?.id as string}
          text={`${data?.animal.fullName} ${data?.animal.nursery.nameEn}`}
        />
      )}
    </>
  );
};
