import { Loader, NurseryIcon, PhoneIcon } from '@/app/icons';
import { EmailDog } from '@/app/icons/EmailDog';
import { LINK_ACCOUNT } from '@/app/navigation';
import { TGoodDto } from '@/entities/goods-controller';
import { useGetForeignUserQuery } from '@/entities/user-controller';
import { CallModal } from '@/features/modals';
import { CustomAvatar } from '@/shared/components';
import { useHistory, useModal } from '@/shared/hooks';
import { Body3, Button, Caption4, Headline5, Headline6 } from '@/shared/ui';
import { countryCityConverter } from '@/shared/utils';
import { Flex } from 'antd';

type TProps = {
  accountId: string;
  good: TGoodDto;
};

export const GoodsSeller = ({ accountId, good }: TProps) => {
  const { isOpen, open, close } = useModal();
  const { data } = useGetForeignUserQuery(accountId);
  const navigate = useHistory();

  if (!data) {
    return <Loader />;
  }

  const {
    country,
    city,
    animal: {
      nursery: { nameEn },
    },
    email,
    phone,
  } = good;
  const { avatar, name, surname } = data;

  const onOpenAccount = () => {
    navigate({
      pathname: `${LINK_ACCOUNT}/${accountId}`,
    });
  };

  return (
    <Flex gap={16} vertical>
      <Headline5 className="lg:text-headline3">Продавец</Headline5>
      <Flex gap={16}>
        <CustomAvatar
          size="132px"
          src={avatar?.url}
          onClick={onOpenAccount}
          style={{ cursor: 'pointer' }}
          name={`${name} ${surname}`}
        />
        <Flex vertical>
          <Headline6 className="lg:text-headline4">{`${name} ${surname}`}</Headline6>
          <Flex gap={4} align="center">
            <NurseryIcon className="text-neutral4 w-xs h-xs lg:w-md lg:h-md" />
            <Body3 className="font-semibold lg:text-headline6">{nameEn}</Body3>
          </Flex>
          {country && city && (
            <Caption4 className="lg:text-body2">{countryCityConverter(country.name, city.name)}</Caption4>
          )}
          <Flex gap={4} align="center">
            <EmailDog className="text-neutral4 w-[16px] h-[16px]" />
            <Body3 className="font-semibold lg:text-headline6">{email}</Body3>
          </Flex>
        </Flex>
      </Flex>
      {phone && (
        <Button
          icon={<PhoneIcon className="w-xs h-xs text-neutral2" />}
          size="x-small"
          className="w-full"
          type="default"
          onClick={open}
        >
          Позвонить
        </Button>
      )}
      {isOpen && <CallModal name={name} phone={phone} onCancel={close} />}
    </Flex>
  );
};
