import { Flex } from 'antd';
import { Headline3, Body2, Headline5 } from '@/shared/ui';
import { HeartFill } from '@/app/icons';
import { TariffsPlans } from '@/pages/shop/console/tariffs/tariffs-plans';

export const Tariffs = () => {
  return (
    <Flex vertical gap={24}>
      <Flex vertical gap={8}>
        <Headline3 className="mx-auto">Тарифы BREED SHOW</Headline3>
        <Body2>
          Мы рады предложить вам три тарифа на запуск нашего магазина:{' '}
          <span className="font-semibold">Free, Venture</span> и <span className="font-semibold">Business</span>
        </Body2>
        <Body2>
          На бесплатном тарифе вы можете понять свои потребности, а при покупке тарифа для Малого Бизнеса на год вы не
          только получите доступ ко всем текущим функциям, но и все будущие обновления вашего тарифа в течение года без
          изменения стоимости
        </Body2>
      </Flex>
      <Flex vertical gap={8}>
        <Flex gap={8} align="center" className="mx-auto">
          <HeartFill className="w-[20px] text-primary3" />
          <Headline5>Если вам нравится то, что мы делаем, поддержите наш проект!</Headline5>
          <HeartFill className="w-[20px] text-primary3" />
        </Flex>
        <Body2>
          Вы можете выбрать один из тарифных планов и оплатить его — это отличный способ помочь развитию платформы. Пока
          опции ещё в разработке, но ваш вклад уже сейчас ускоряет их реализацию. Или вы можете сделать пожертвование
          через ЮМани.
        </Body2>
        <Body2>Спасибо, что Вы с нами!</Body2>
      </Flex>
      <TariffsPlans />
    </Flex>
  );
};
