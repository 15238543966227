import { Body3, Caption2 } from '@/shared/ui';
import { Flex } from 'antd';
import { Email, Phone, Telegram, Vkontakte } from '@/app/icons';
import { LINKS } from '@/app/navigation';
import { ReactNode } from 'react';
import cn from 'classnames';

const ContactWrapper = ({
  icon,
  children,
  underline,
}: {
  icon: ReactNode;
  children: ReactNode;
  underline?: boolean;
}) => (
  <Caption2 className={cn('text-neutral4 flex items-center gap-[4px]', { underline })}>
    {icon}
    {children}
  </Caption2>
);

export const ProjectContacts = ({ isSidebar }: { isSidebar?: boolean }) => (
  <Flex vertical>
    <Body3 className="mb-[4px] text-neutral4 font-semibold lg:text-headline6">Наши контакты</Body3>
    {isSidebar ? (
      <Flex justify="space-between">
        <Flex vertical>
          <ContactWrapper icon={<Phone />}>+375 (44) 795–11–57</ContactWrapper>
          <ContactWrapper underline icon={<Email />}>
            info@breed.show
          </ContactWrapper>
        </Flex>
        <Flex vertical>
          <ContactWrapper underline icon={<Telegram />}>
            <a href={LINKS.TG} target="_blank" rel="noreferrer">
              t.me/breedshow
            </a>
          </ContactWrapper>
          <ContactWrapper underline icon={<Vkontakte />}>
            <a href={LINKS.VK} target="_blank" rel="noreferrer">
              {LINKS.VK}
            </a>
          </ContactWrapper>
        </Flex>
      </Flex>
    ) : (
      <>
        <ContactWrapper icon={<Phone />}>+375 (44) 795–11–57</ContactWrapper>
        <ContactWrapper underline icon={<Email />}>
          info@breed.show
        </ContactWrapper>
        <ContactWrapper underline icon={<Telegram />}>
          <a href={LINKS.TG} target="_blank" rel="noreferrer">
            t.me/breedshow
          </a>
        </ContactWrapper>
        <ContactWrapper underline icon={<Vkontakte />}>
          <a href={LINKS.VK} target="_blank" rel="noreferrer">
            {LINKS.VK}
          </a>
        </ContactWrapper>
      </>
    )}
  </Flex>
);
