/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select as AntSelect, SelectProps, Spin } from 'antd';
import cn from 'classnames';

import './styles.scss';
import { Controller, FieldError } from 'react-hook-form';
import { SelectIcon } from '@/app/icons/SelectIcon';
import { TControllerProps } from '@/shared/common';
import { ErrorWrapper, LabelWrapper, TLabelProps } from '@/shared/ui';
import { Loader } from '@/app/icons';
import { DefaultOptionType, BaseOptionType } from 'rc-select/lib/Select';

type TSelect<OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType, ValueType = any> = SelectProps<
  ValueType,
  OptionType
> & {
  labelProps?: TLabelProps;
  showErrorMsg?: boolean;
  error?: FieldError;
};

export type TSelectProps<
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
  ValueType = any,
> = TSelect<OptionType, ValueType> & {
  controllerProps?: TControllerProps;
};

export const SelectComponent = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
  labelProps,
  className,
  loading,
  notFoundContent,
  value,
  error,
  showErrorMsg = true,
  ...props
}: TSelect<OptionType, ValueType>) => (
  <LabelWrapper {...labelProps}>
    <ErrorWrapper message={showErrorMsg ? error?.message : ''}>
      <AntSelect
        showSearch
        labelRender={({ label }) => label || ''}
        optionFilterProp="label"
        status={error && 'error'}
        className={cn('custom-select w-full', className)}
        size="large"
        notFoundContent={loading ? <Spin size="small" /> : notFoundContent}
        suffixIcon={
          loading ? (
            <Loader />
          ) : (
            <SelectIcon
              className={cn('text-neutral2', {
                'text-primary3': error,
              })}
            />
          )
        }
        value={value === 0 ? null : value}
        {...props}
      />
    </ErrorWrapper>
  </LabelWrapper>
);

export const Select = <OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType, ValueType = any>({
  controllerProps,
  ...props
}: TSelectProps<OptionType, ValueType>) => {
  if (!controllerProps) {
    return <SelectComponent {...props} />;
  }

  return (
    <Controller
      render={({ field, fieldState: { error } }) => <SelectComponent error={error} {...field} {...props} />}
      {...controllerProps}
    />
  );
};
