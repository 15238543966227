import { axiosBaseQuery } from '@/app/core';
import {
  TCurrencyDto,
  TCurrencyRateDto,
  TGoodDto,
  TGoodDtoExt,
  TGoodQuery,
  TGoodRequestDto,
  TPageGoodDto,
} from './types';
import { TSelectOption } from '@/shared/common';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

const GOODS_PATH = 'goods';

export const goodsApi = createApi({
  reducerPath: 'goodsApi',
  tagTypes: ['GOODS'],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getGoods: builder.query<TPageGoodDto, Partial<TGoodQuery>>({
      query: (params) => ({
        url: GOODS_PATH,
        method: 'GET',
        params,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs: { query } }) => {
        if (query) {
          return `${endpointName}/${query}`;
        }
        return endpointName;
      },
      merge: ({ content }, data) => {
        content.push(...data.content);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
      keepUnusedDataFor: 0,
    }),
    getGoodsRecommendation: builder.query<TGoodDto[], void>({
      query: () => ({
        url: `${GOODS_PATH}/recommend`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    getGoodsSimilar: builder.query<TPageGoodDto, { id: string; params: Partial<TGoodQuery> }>({
      query: ({ id, params }) => ({
        url: `${GOODS_PATH}/${id}/similar`,
        method: 'GET',
        params,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs: { id } }) => {
        return `${endpointName}/${id}`;
      },
      merge: ({ content }, data) => {
        content.push(...data.content);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.params.page !== previousArg?.params.page;
      },
      keepUnusedDataFor: 0,
    }),
    getGoodsById: builder.query<TGoodDtoExt, string>({
      query: (id) => ({
        url: `${GOODS_PATH}/${id}`,
      }),
      providesTags: (result) => (result ? [{ type: 'GOODS', id: result.id }] : []),
      keepUnusedDataFor: 0,
    }),
    postGoods: builder.mutation<TGoodDto, TGoodRequestDto>({
      query: (data) => ({
        url: GOODS_PATH,
        method: 'POST',
        data,
      }),
    }),
    getCurrencies: builder.query<(TCurrencyDto & TSelectOption)[], void>({
      query: () => ({
        url: `${GOODS_PATH}/currencies`,
      }),
      transformResponse: (data: TCurrencyDto[]) => data.map((item) => ({ value: item.id, label: item.name, ...item })),
    }),
    getCurrencyRates: builder.query<TCurrencyRateDto[], number>({
      query: (id: number) => ({
        url: `${GOODS_PATH}/currencies/${id}/rates`,
      }),
    }),
    deleteGoods: builder.mutation<void, string>({
      query: (id) => ({
        url: `${GOODS_PATH}/${id}`,
        method: 'DELETE',
      }),
    }),
    putGoods: builder.mutation<TGoodDto, Partial<TGoodRequestDto>>({
      query: (data) => ({
        url: `${GOODS_PATH}/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'GOODS', id: result.id }] : []),
    }),
  }),
});

export const {
  useGetGoodsSimilarQuery,
  usePostGoodsMutation,
  useGetGoodsQuery,
  useGetGoodsRecommendationQuery,
  useGetGoodsByIdQuery,
  useGetCurrenciesQuery,
  useDeleteGoodsMutation,
  useGetCurrencyRatesQuery,
  usePutGoodsMutation,
} = goodsApi;
