import RcPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { NotificationPopup } from '@/shared/components';
import { Body2, TPopupProps } from '@/shared/ui';

type TProps = TPopupProps & {
  name: string;
  phone: string;
};

export const CallModal = ({ name, phone, onCancel }: TProps) => (
  <NotificationPopup open title={name} onCancel={onCancel}>
    <RcPhoneInput
      value={`+${phone}`}
      inputProps={{
        readOnly: true,
        className: 'cursor-pointer text-headline3',
      }}
      buttonStyle={{ display: 'none' }}
      disableDropdown
      onClick={() => {
        window.location.href = `tel:${phone}`;
      }}
    />
    <Body2>
      Пожалуйста, скажите продавцу, что звоните с <strong>BREED.SHOW MarketPlace</strong>
    </Body2>
  </NotificationPopup>
);
