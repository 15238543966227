import { SVGProps } from 'react';

export const Telegram = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="6" cy="6" r="5.7" stroke="#8A91A9" strokeWidth="0.6" />
    <path
      d="M8.49496 3.9533C8.54172 3.65124 8.25453 3.41283 7.98596 3.53075L2.63681 5.87932C2.44421 5.96388 2.4583 6.2556 2.65805 6.31921L3.76118 6.6705C3.97172 6.73755 4.1997 6.70288 4.38355 6.57587L6.87061 4.8576C6.94561 4.80579 7.02736 4.91242 6.96328 4.97849L5.17304 6.82424C4.99938 7.00329 5.03385 7.30668 5.24274 7.43768L7.24711 8.69461C7.47192 8.83559 7.76113 8.69396 7.80318 8.42231L8.49496 3.9533Z"
      fill="#8A91A9"
    />
  </svg>
);
