import { Flex } from 'antd';
import { Body2, Headline4 } from '@/shared/ui';
import { Success } from '@/app/icons';
import { ReactNode } from 'react';

const Wrapper = ({ children }: { children: ReactNode }) => (
  <Flex gap={8}>
    <Success className="min-w-md" />
    <Body2>{children}</Body2>
  </Flex>
);

export const PaymentRules = () => {
  return (
    <Flex vertical gap={8}>
      <Headline4 className="mx-auto">Перед оплатой важно ознакомиться</Headline4>
      <Wrapper>
        Оплата осуществляется банковской платёжной картой через систему{' '}
        <a href="https://www.webpay.by" target="_blank" className="text-primary1 underline" rel="noreferrer">
          WebPay
        </a>
      </Wrapper>
      <Wrapper>
        Безопасный сервер WEBPAY устанавливает шифрованное соединение по защищенному протоколу TLS и конфиденциально
        принимает от клиента данные его платёжной карты (номер карты, имя держателя, дату окончания действия, и
        контрольный номер банковской карточке CVC/CVC2)
      </Wrapper>
      <Wrapper>
        После совершения оплаты с использованием банковской карты необходимо сохранять полученные карт-чеки
        (подтверждения об оплате) для сверки с выпиской из карт-счёта (с целью подтверждения совершённых операций в
        случае возникновения спорных ситуаций)
      </Wrapper>
      <Wrapper>
        В случае, если Вы не получили заказ (не оказана услуга), Вам необходимо обратиться в службу технической
        поддержки по телефону <span className="font-semibold">+375 (44) 795–11–57</span> или e-mail{' '}
        <span className="font-semibold">info@breed.show.</span> Вас проконсультируют
      </Wrapper>
    </Flex>
  );
};
