import { DevelopmentSteps } from './development-steps';
import { FlexPage } from '@/shared/containers';
import { Tariffs } from './tariffs';
import { PaymentRules } from './payment-rules';

export const Console = () => {
  return (
    <FlexPage>
      <DevelopmentSteps />
      <Tariffs />
      <PaymentRules />
    </FlexPage>
  );
};
