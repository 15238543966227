import { InputProps, InputRef, Input as AntInput } from 'antd';
import { Controller, FieldError } from 'react-hook-form';
import { TControllerProps } from '@/shared/common';
import { ErrorWrapper, LabelWrapper, TLabelProps } from '@/shared/ui';
import { ForwardedRef, forwardRef } from 'react';

type TInput = InputProps & {
  labelProps?: TLabelProps;
  error?: FieldError;
  showErrorMsg?: boolean;
  trim?: boolean;
};

export type TInputProps = TInput & {
  controllerProps: TControllerProps;
};

export const InputComponent = forwardRef(
  (
    { labelProps, error, showErrorMsg = true, trim, className, ...props }: TInput,
    ref: ForwardedRef<InputRef | null>,
  ) => (
    <LabelWrapper {...labelProps}>
      <ErrorWrapper message={showErrorMsg ? error?.message : ''}>
        <AntInput
          size="large"
          ref={ref}
          status={error && 'error'}
          value={trim && typeof props.value === 'string' ? props.value.trim() : props.value}
          className={className}
          {...props}
        />
      </ErrorWrapper>
    </LabelWrapper>
  ),
);

export const Input = ({ controllerProps, trim, ...props }: TInputProps) => (
  <Controller
    render={({ field: { ref, ...rest }, fieldState: { error } }) => (
      <InputComponent error={error} trim={trim} ref={ref} {...rest} {...props} />
    )}
    {...controllerProps}
  />
);
