import { InfoIcon, Warning } from '@/app/icons';
import { selectCurrentAccountId, useTypedSelector } from '@/app/store';
import { TAnimalDto, useGetAnimalsQuery } from '@/entities/animal-controller';
import { SPECIES } from '@/entities/species-controller';
import { CitySelect, CountrySelect, CurrencySelect } from '@/features/select';
import { Divider } from '@/shared/components';
import { FlexPage } from '@/shared/containers';
import {
  Body2,
  Body3,
  Button,
  Headline4,
  Input,
  LabelWrapper,
  NumberInput,
  PhoneInput,
  Popup,
  RadioGroup,
  Select,
  TPopupProps,
} from '@/shared/ui';
import { Flex } from 'antd';
import { ReactNode } from 'react';
import { FormProvider } from 'react-hook-form';
import { SHOWROOM_FORM_FIELDS, TAddGoodsSchema, useShowroomForm } from './hooks';
import { TSelectOption } from '@/shared/common';
import { queries } from '@/shared/utils';
import { useModal } from '@/shared/hooks';
import { AddAnimalPopup } from './components';
import { TAnimalFormProps } from '@/widgets/animal/animal-crud-popup/hooks';
import { TGoodDto } from '@/entities/goods-controller';
import { useLocation } from 'react-router-dom';
import { LINK_RACY } from '@/app/navigation';
import { NAME } from '@/widgets/registration/RegisterForm/constants';

type TProps = TPopupProps & {
  defaultValues?: Partial<TAddGoodsSchema>;
  speciesId: SPECIES;
  data?: TGoodDto;
};

export const OPTIONS = [
  { value: false, label: 'Не осуществляю' },
  { value: true, label: 'Осуществляю' },
];

const Block = ({
  children,
  subtitle,
  title,
  className,
}: {
  children: ReactNode;
  title: string;
  subtitle?: string;
  className?: string;
}) => (
  <Flex vertical gap={className ? 0 : 16} className={className}>
    <Flex vertical>
      <Headline4>{title}</Headline4>
      {subtitle && (
        <Flex gap={8} align="center">
          <Warning className="text-neutral4" />
          <Body2 className="text-neutral4">{subtitle}</Body2>
        </Flex>
      )}
    </Flex>
    <Flex vertical gap={24}>
      {children}
    </Flex>
  </Flex>
);

export const AddShowroomProductModal = ({ speciesId, defaultValues, open, onCancel, data, ...props }: TProps) => {
  const accountId = useTypedSelector(selectCurrentAccountId);
  const { pathname } = useLocation();

  const { formMethods, onFormSubmit, isLoading } = useShowroomForm({
    defaultValues,
    speciesId,
    accountId: String(accountId),
    onClose: onCancel,
    data,
  });
  const { isOpen: isOpenAddAnimal, open: openAddAnimal, close: closeAddAnimal } = useModal();

  const animalQuery = queries.equal('owner.id', String(accountId));
  const {
    data: animals,
    refetch,
    isFetching,
  } = useGetAnimalsQuery(
    { query: animalQuery },
    {
      skip: !!data,
      selectFromResult: ({ data, ...rest }) => ({
        data: data?.filter(({ canPublishToMarket }) => canPublishToMarket),
        ...rest,
      }),
    },
  );

  const { setValue, watch } = formMethods;
  const [watchCountry] = watch([SHOWROOM_FORM_FIELDS.country, SHOWROOM_FORM_FIELDS.animal]);

  const handleChangeCountry = (value: number) => {
    setValue(SHOWROOM_FORM_FIELDS.country, value, { shouldValidate: true });
    setValue(SHOWROOM_FORM_FIELDS.city, 0);
  };

  const handleChangeAnimal = (id: string, animal: (TAnimalDto & TSelectOption) | (TAnimalDto & TSelectOption)[]) => {
    if (!Array.isArray(animal)) {
      setValue(SHOWROOM_FORM_FIELDS.animal, id, { shouldValidate: true });
      const { liveCountry, city } = animal;
      liveCountry?.id && setValue(SHOWROOM_FORM_FIELDS.country, liveCountry.id, { shouldValidate: true });
      city?.id && setValue(SHOWROOM_FORM_FIELDS.city, city.id, { shouldValidate: true });
    }
  };

  const handleCreateAnimal: TAnimalFormProps['onSubmitFinish'] = async (data) => {
    closeAddAnimal();

    await refetch();
    const { id, liveCountryId, cityId } = data;
    setValue(SHOWROOM_FORM_FIELDS.animal, id, { shouldValidate: true });
    setValue(SHOWROOM_FORM_FIELDS.country, liveCountryId, { shouldValidate: true });
    setValue(SHOWROOM_FORM_FIELDS.city, cityId, { shouldValidate: true });
  };

  const isAnimalSelectDisabled = pathname.includes(LINK_RACY) || !!data;

  return (
    <>
      <Popup
        size="MEDIUM"
        title={data ? 'Редактирование карточки' : 'Публикация на продажу'}
        cancelText="Отменить"
        okText={data ? 'Сохранить' : 'Опубликовать'}
        onOk={onFormSubmit}
        okButtonProps={{ loading: isLoading }}
        open={open && !isOpenAddAnimal}
        onCancel={onCancel}
        {...props}
      >
        <FormProvider {...formMethods}>
          <FlexPage>
            <Block title="Информация о питомце">
              <LabelWrapper label="Кличка" contentClassName="flex flex-col gap-xs">
                <Select
                  labelRender={data && (() => `${data.animal.fullName} ${data.animal.nursery.nameEn}`)}
                  onChange={handleChangeAnimal}
                  loading={isFetching}
                  options={animals}
                  disabled={isAnimalSelectDisabled}
                  controllerProps={{ name: SHOWROOM_FORM_FIELDS.animal }}
                />
                {!isAnimalSelectDisabled && (
                  <>
                    <Flex className="text-neutral4" gap={8}>
                      <InfoIcon className="min-w-xs mt-[4px]" />
                      <Body3>Если нужного питомца еще нет в системе, пожалуйста, добавьте его.</Body3>
                    </Flex>
                    <Button className="w-full" onClick={openAddAnimal}>
                      Добавить питомца
                    </Button>
                  </>
                )}
              </LabelWrapper>
              <LabelWrapper label="Цена">
                <Flex gap={16} className="w-full">
                  <NumberInput
                    controllerProps={{ name: SHOWROOM_FORM_FIELDS.price }}
                    decimalLimit={2}
                    showErrorMsg={false}
                  />
                  <CurrencySelect controllerProps={{ name: SHOWROOM_FORM_FIELDS.currency }} />
                </Flex>
              </LabelWrapper>
            </Block>
            <Divider />
            <Block
              className={data ? 'block-wrapper' : ''}
              title={data ? '' : 'Ваши контактные данные'}
              subtitle={data ? '' : 'Данная информация будет доступна другим пользователям'}
            >
              <PhoneInput controllerProps={{ name: SHOWROOM_FORM_FIELDS.phone }} labelProps={{ label: 'Телефон' }} />
              <Input controllerProps={{ name: SHOWROOM_FORM_FIELDS.email }} labelProps={{ label: 'E-mail' }} />
            </Block>
            <Divider />
            <Block title="Настройки доставки">
              <RadioGroup
                className="w-full"
                labelProps={{ label: 'Доставка' }}
                options={OPTIONS}
                controllerProps={{ name: SHOWROOM_FORM_FIELDS.delivery }}
              />
              <LabelWrapper label="Местоположение">
                <Flex vertical gap={12} className="w-full">
                  <CountrySelect
                    labelProps={{ label: 'Страна', isHorizontal: false }}
                    controllerProps={{ name: SHOWROOM_FORM_FIELDS.country }}
                    onChange={handleChangeCountry}
                  />
                  <CitySelect
                    countryId={watchCountry}
                    labelProps={{ label: 'Город', isHorizontal: false }}
                    controllerProps={{ name: SHOWROOM_FORM_FIELDS.city }}
                  />
                </Flex>
              </LabelWrapper>
            </Block>
          </FlexPage>
        </FormProvider>
      </Popup>
      {isOpenAddAnimal && (
        <AddAnimalPopup onSubmitFinish={handleCreateAnimal} open={isOpenAddAnimal} onCancel={closeAddAnimal} />
      )}
    </>
  );
};
