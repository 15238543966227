import { FlexPage } from '@/shared/containers';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { Breadcrumb, TBreadcrumbProps } from '@/shared/components';
import { useGetGoodsByIdQuery } from '@/entities/goods-controller';
import { Loader } from '@/app/icons';
import { HOME_PATHS } from '@/app/navigation';
import { Flex } from 'antd';
import { GoodsCrudDropdown } from '@/features/goods';

export const MyShowRoom = () => {
  const { goodsId } = useParams();
  const { data, isFetching } = useGetGoodsByIdQuery(String(goodsId), { skip: !goodsId });
  const { pathname } = useLocation();

  const breadcrumbItems: TBreadcrumbProps['items'] = [
    {
      title: 'Шоурум',
      path: HOME_PATHS.showroom,
    },
    {
      title: isFetching ? <Loader /> : `${data?.animal.fullName} ${data?.animal.nursery.nameEn}`,
    },
  ];

  return (
    <FlexPage>
      <Flex justify="space-between">
        {pathname !== HOME_PATHS.showroom && <Breadcrumb items={breadcrumbItems} />}
        <Flex justify="space-between" align="center">
          {data?.canEdit && goodsId && <GoodsCrudDropdown data={data} />}
        </Flex>
      </Flex>
      <Outlet />
    </FlexPage>
  );
};
