import { TRoute } from '@/app/navigation';
import { Console, ShopPage } from 'src/pages/shop';

export const SHOP_PATHS = {
  shop: '/shop',
} as const;

export const SHOP_ROUTES: TRoute = {
  path: SHOP_PATHS.shop,
  element: <ShopPage />,
  nestedRoutes: [
    {
      index: true,
      element: <Console />,
    },
  ],
};
