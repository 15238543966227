import { ThemeConfig } from 'antd/es/config-provider/context';
import { useEffect, useState } from 'react';
import { useMedia } from '@/shared/hooks';

import colors from './colors';

const initialToken: ThemeConfig['token'] = {
  colorPrimary: colors.primary1,
  colorError: colors.primary3,
  colorBorder: colors.neutral6,
  colorText: colors.neutral2,
  controlHeightLG: 48,
  fontFamily: 'Montserrat',
  fontSizeLG: 14,
  fontWeightStrong: 600,
};

const initialComponents: ThemeConfig['components'] = {
  Button: {
    borderRadius: 90,
    colorBgContainerDisabled: colors.neutral5,
    borderColorDisabled: colors.neutral5,
    defaultBg: colors.neutral6,
    defaultHoverBg: colors.neutral6,
    defaultHoverBorderColor: 'none',
    defaultBorderColor: 'none',
    defaultHoverColor: 'none',
    defaultActiveBorderColor: 'none',
    defaultActiveBg: 'none',
    defaultActiveColor: 'none',
  },
  Progress: {
    remainingColor: colors.neutral6,
    colorSuccess: colors.primary4,
  },
  Radio: {
    radioSize: 24,
    dotSize: 12,
  },
  Checkbox: {
    controlInteractiveSize: 24,
  },
};

export const useAntToken = () => {
  const [token, setToken] = useState<ThemeConfig['token']>(initialToken);
  const [components, setComponents] = useState<ThemeConfig['components']>(initialComponents);
  const { isTablet, isMobile } = useMedia();

  useEffect(() => {
    if (isTablet) {
      setToken({
        ...initialToken,
        controlHeightLG: 32,
        fontSizeLG: 12,
      });
      setComponents({
        ...initialComponents,
        Radio: {
          radioSize: 16,
          dotSize: 8,
        },
      });
    } else if (isMobile) {
      setToken({ ...initialToken, fontSizeLG: 14 });
      setComponents({ ...initialComponents });
    } else {
      setToken({ ...initialToken });
      setComponents({ ...initialComponents });
    }
  }, [isMobile, isTablet]);

  return { token, components };
};
