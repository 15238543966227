export const LINK_ACCOUNT = '/account';
export const LINK_RACY = '/racy';

// account params
export enum SEARCH_PARAMS {
  TAB = 'tab',
}

export enum NURSERY_TABS {
  PRODUCERS = 'producers',
  LITTERS = 'litters',
}

export const LINKS = {
  TG: 'https://t.me/breedshow',
  VK: 'https://vk.com/breed.show',
  PRIVACY: 'https://breedshow.by/page/privacy',
  AGREEMENT: 'https://breedshow.by/page/agreement',
};
