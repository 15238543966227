import { Flex } from 'antd';
import { CustomImage } from '@/shared/components';
import { Card, Body2 } from '@/shared/ui';
import './styles.scss';
import { AnimalAvatar } from '@/app/icons/AnimalAvatar';
import { TAnimalDto } from '@/entities/animal-controller';
import { LINK_RACY } from '@/app/navigation';
import { useNavigate } from 'react-router';
import { useMedia } from '@/shared/hooks';

type TProps = {
  animal: Partial<TAnimalDto>;
  isList: boolean;
};

export const LitterIndividualsCard = ({ animal, isList }: TProps) => {
  const { images, fullName, speciesSex, colors, id, nursery } = animal;
  const navigate = useNavigate();
  const handleClick = () => navigate(`${LINK_RACY}/${id}`);
  const { isMobile } = useMedia();

  const ListView = () => {
    return (
      <>
        {images?.length ? (
          <CustomImage
            width={80}
            square
            styles={{
              objectFit: 'cover',
              overflow: 'hidden',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
            src={images[0].url}
          />
        ) : (
          <AnimalAvatar width={80} height={80} cursor="pointer" />
        )}
        <Flex
          justify="space-between"
          className="w-full border rounded-xxs py-[3px] px-[9px] max-w-[240px] flex-wrap lg:p-3 lg:max-w-[451px] lg:flex-nowrap"
        >
          <Body2 className="inline-block gap-0.5 lg:max-w-[140px] w-full overflow-hidden text-ellipsis whitespace-nowrap">
            <Body2 className="contents font-semibold">Имя: </Body2>
            {fullName} {nursery?.nameEn}
          </Body2>
          <Body2 className="inline-block gap-0.5 lg:max-w-[140px] w-full overflow-hidden text-ellipsis whitespace-nowrap">
            <Body2 className="contents font-semibold">Окрас: </Body2>
            {colors?.map((color) => color.code).join(' ')}
          </Body2>
          <Body2 className="flex gap-0.5 lg:max-w-[104px] w-full">
            <Body2 className="font-semibold">Пол: </Body2>
            {speciesSex?.labelSingular}
          </Body2>
        </Flex>
      </>
    );
  };

  const GridView = () => {
    return (
      <Flex gap={16}>
        {images?.length ? (
          <CustomImage
            square
            width={isMobile ? 156 : 169}
            styles={{
              objectFit: 'cover',
              overflow: 'hidden',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
            src={images[0].url}
          />
        ) : (
          <AnimalAvatar width={isMobile ? 156 : 169} />
        )}
      </Flex>
    );
  };

  return (
    <Card
      className="border-0 individuals"
      onClick={handleClick}
      metaProps={{
        description: (
          <Flex vertical={!isList} align={isList ? 'center' : 'start'} gap={8}>
            {isList ? <ListView /> : <GridView />}
          </Flex>
        ),
      }}
    />
  );
};
