import { SVGProps } from 'react';

export const Phone = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="6" cy="6" r="5.7" stroke="#8A91A9" strokeWidth="0.6" />
    <path
      d="M3.25508 3.83676L3.25509 3.83676L3.2554 3.83597C3.31882 3.67476 3.41979 3.52496 3.56375 3.38916L3.56377 3.38918L3.56604 3.38694C3.7354 3.22023 3.90919 3.14805 4.08481 3.14805C4.14781 3.14805 4.20944 3.16161 4.2629 3.18728L4.2629 3.18729L4.26495 3.18824C4.32067 3.21396 4.36798 3.25125 4.40548 3.30543L4.40548 3.30543L4.40648 3.30684L5.10248 4.28784L5.10247 4.28785L5.10308 4.28869C5.15165 4.35615 5.1843 4.41486 5.20598 4.46784L5.20596 4.46785L5.20694 4.47013C5.22809 4.51949 5.23681 4.5622 5.23681 4.59405C5.23681 4.63585 5.22484 4.68115 5.19568 4.72905L5.19562 4.72901L5.19323 4.73324C5.16261 4.78742 5.11495 4.84878 5.04975 4.91398L5.04973 4.91396L5.04771 4.91605L4.82058 5.15216C4.75794 5.2154 4.72981 5.29319 4.72981 5.37705C4.72981 5.41526 4.73483 5.44858 4.74329 5.48243L4.74536 5.49072L4.74836 5.49872C4.75366 5.51283 4.75896 5.52535 4.76273 5.53423C4.7631 5.5351 4.76345 5.53594 4.76379 5.53674C4.76809 5.54689 4.76962 5.55081 4.77051 5.55348L4.77473 5.56615L4.78113 5.57787C4.84222 5.68987 4.94265 5.8278 5.0773 5.98694L5.0773 5.98694L5.07839 5.9882C5.21491 6.14597 5.36106 6.30736 5.51974 6.4691L5.51974 6.4691L5.52075 6.47011C5.53704 6.48641 5.55391 6.50247 5.56925 6.51708L5.56986 6.51767C5.58586 6.5329 5.60026 6.54663 5.61375 6.56011L5.61374 6.56012L5.6151 6.56145C5.67593 6.62076 5.67697 6.71675 5.61675 6.77698L5.06175 7.33198C5.00417 7.38956 4.91189 7.39177 4.84927 7.33338C4.83209 7.31624 4.8151 7.30002 4.79942 7.28506L4.79888 7.28454C4.78227 7.26869 4.76709 7.25419 4.75209 7.23919C4.44844 6.93257 4.17457 6.61153 3.93034 6.27611C3.68991 5.94182 3.49803 5.60983 3.35919 5.28314C3.22432 4.95579 3.15781 4.64853 3.15781 4.36005C3.15781 4.17382 3.19064 3.99786 3.25508 3.83676Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.3"
    />
    <path
      d="M8.11663 8.68338C8.11739 8.68301 8.11869 8.68237 8.12013 8.68169L8.11796 8.68272L8.11663 8.68338ZM8.11663 8.68338C8.11601 8.68369 8.11576 8.68381 8.11613 8.68363L8.11663 8.68338ZM8.716 8.12275L8.71595 8.12273L8.71379 8.1273C8.67174 8.21636 8.61786 8.29955 8.54662 8.37818C8.42812 8.50878 8.30279 8.60103 8.16612 8.66224L8.12747 8.67796C7.97747 8.73897 7.81427 8.77238 7.63625 8.77238C7.36583 8.77238 7.06886 8.70876 6.74645 8.57138C6.41899 8.43186 6.08902 8.24289 5.75959 8.00314C5.71168 7.96752 5.6648 7.93264 5.61896 7.89786L6.28915 7.22768C6.32054 7.24712 6.35084 7.26449 6.37985 7.27969L6.39002 7.28502L6.40069 7.28928C6.40222 7.2899 6.40553 7.29133 6.41525 7.29579C6.416 7.29613 6.41678 7.29649 6.41759 7.29686C6.42574 7.3006 6.43724 7.30588 6.44962 7.31118L6.44952 7.3114L6.45925 7.31505C6.51613 7.33638 6.56882 7.34137 6.61325 7.34137C6.72679 7.34137 6.82192 7.29761 6.89473 7.22509L7.12229 7.00052L7.1223 7.00053L7.12335 6.99947C7.18925 6.93357 7.24291 6.89347 7.28433 6.87186L7.2909 6.86843L7.29724 6.86457C7.3354 6.84134 7.36439 6.83438 7.39325 6.83438C7.41727 6.83438 7.44838 6.83917 7.49106 6.85662C7.53773 6.87572 7.59208 6.90556 7.65791 6.9502L8.649 7.65384L8.64899 7.65385L8.65118 7.65537C8.69864 7.68822 8.72284 7.71938 8.73692 7.74992C8.75761 7.80266 8.76725 7.84805 8.76725 7.89638C8.76725 7.95344 8.75708 8.01431 8.73591 8.07415C8.72678 8.09846 8.72177 8.11123 8.716 8.12275Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.45"
    />
  </svg>
);
