import { ProjectContacts, ProjectLinks, ProjectPayments, ProjectRegistry } from '@/features/project-info';
import { Flex } from 'antd';

export const ProjectInfoSidebar = () => (
  <Flex className="justify-end h-full" vertical gap={16}>
    <ProjectContacts isSidebar />
    <ProjectRegistry />
    <ProjectPayments isSidebar />
    <ProjectLinks />
  </Flex>
);
