import { Route, Routes } from 'react-router-dom';

import { MainLayout } from '@/widgets/layouts';
import { privateRoutes, publicRoutes, TRoute } from '@/app/navigation';
import { useEffect, useMemo } from 'react';
import { setUserRoles, useTypedDispatch } from '@/app/store';
import { getStorageItem, StorageKeys } from '@/shared/utils/storage';

import './global.scss';
import { useCurrency } from '@/shared/hooks/currency';

const renderRoutes = (routes: TRoute[]) => {
  return routes.map((route) => {
    const { nestedRoutes } = route;

    if (nestedRoutes && nestedRoutes.length) {
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Route key={route.path} {...route}>
          {renderRoutes(nestedRoutes)}
        </Route>
      );
    }

    return <Route key={route.path} {...route} />;
  });
};

export const App = () => {
  const dispatch = useTypedDispatch();
  const accessToken = getStorageItem(StorageKeys.ACCESS_TOKEN);

  const availableRoutes = accessToken ? privateRoutes : publicRoutes;

  const routes = useMemo(() => renderRoutes(availableRoutes), [availableRoutes]);

  useEffect(() => {
    if (accessToken) {
      dispatch(setUserRoles(accessToken));
    }
  }, [dispatch, accessToken]);

  useCurrency();

  return (
    <Routes>
      <Route element={<MainLayout />}>{routes}</Route>
    </Routes>
  );
};
