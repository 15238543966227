import { RootState } from '@/app/store/types';
import { ROLES, USER_SLICE_NAME } from '@/app/store/slices';

export const selectIsBreeder = (state: RootState) =>
  !!state[USER_SLICE_NAME].roles.find((item) => item === ROLES.BREEDER);
export const selectIsAdmin = (state: RootState) => !!state[USER_SLICE_NAME].roles.find((item) => item === ROLES.ADMIN);
export const selectCurrentAccountId = (state: RootState) => state[USER_SLICE_NAME].accountId;
export const selectCurrency = (state: RootState) => state[USER_SLICE_NAME].currency;
export const selectRates = (state: RootState) => state[USER_SLICE_NAME].rates;
export const selectTariff = (state: RootState) => state[USER_SLICE_NAME].tariffId;
