import {
  TAnimalDto,
  TAnimalRequestDto,
  TAnimaQuery,
  TPageAnimalDto,
  TAnimalSexDto,
  TPedigreeConverted,
  TAnimaParentRequestDto,
  TAnimalLitterRequestDto,
} from './types';
import { TImageInfoDto } from '@/entities/file-controller';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@/app/core';
import { TSelectOption } from '@/shared/common';
import { queries } from '@/shared/utils';

export type TAnimalOption = TAnimalDto & TSelectOption;

const ANIMAL_PATH = 'animals';

export const animalApi = createApi({
  reducerPath: 'animalApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ANIMAL'],
  endpoints: (builder) => ({
    getAnimals: builder.query<TAnimalOption[], TAnimaQuery>({
      query: ({ size = 100, page, query }) => ({
        url: ANIMAL_PATH,
        method: 'GET',
        params: {
          query,
          size,
          page,
        },
      }),
      transformResponse: (response: TPageAnimalDto) =>
        response.content.map((item) => ({
          value: item.id,
          label: `${item?.fullName || ''} ${item?.nursery?.nameEn || ''}`,
          ...item,
        })),
      keepUnusedDataFor: 0,
    }),
    getLittersAnimals: builder.query<TAnimalDto[], string>({
      query: (litterId) => ({
        url: ANIMAL_PATH,
        method: 'GET',
        params: { query: queries.equal('litter.id', litterId) },
      }),
      transformResponse: (response: TPageAnimalDto) =>
        response.content.map((item) => ({
          value: item.id,
          label: `${item?.fullName || ''} ${item?.nursery?.nameEn || ''}`,
          ...item,
        })),
      providesTags: (result, error, litterId) => (result ? [{ type: 'ANIMAL', id: `LITTER_${litterId}` }] : []),
    }),
    postAnimal: builder.mutation<{ id: string }, TAnimalRequestDto>({
      query: (body) => ({
        url: ANIMAL_PATH,
        method: 'POST',
        data: body,
      }),
    }),
    postAnimalLitter: builder.mutation<{ id: string }, TAnimalLitterRequestDto>({
      query: (body) => ({
        url: ANIMAL_PATH,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'ANIMAL', id: `LITTER_${arg.litter}` }],
    }),
    getAnimal: builder.query<TAnimalDto, string>({
      query: (animalId) => ({
        url: `${ANIMAL_PATH}/${animalId}`,
        method: 'GET',
      }),
      providesTags: (result) => (result ? [{ type: 'ANIMAL', id: result.id }] : []),
    }),
    putAnimal: builder.mutation<TAnimalDto, TAnimalRequestDto | TAnimaParentRequestDto>({
      query: (data) => ({
        url: `${ANIMAL_PATH}/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'ANIMAL', id: result.id }] : []),
    }),
    patchAnimal: builder.mutation<TAnimalDto, Partial<TAnimalRequestDto>>({
      query: ({ id, ...data }) => ({
        url: `${ANIMAL_PATH}/${id}`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'ANIMAL', id: result.id }] : []),
    }),
    postAnimalImage: builder.mutation<void, { animalId: string; fileList: File[] }>({
      query: ({ animalId, fileList }) => {
        const formData = new FormData();
        fileList.forEach((file) => {
          formData.append('images', file);
        });
        return {
          url: `${ANIMAL_PATH}/attachImages`,
          method: 'POST',
          params: {
            animalId,
          },
          data: formData,
        };
      },
      invalidatesTags: (result, error, arg) => (!error ? [{ type: 'ANIMAL', id: arg.animalId }] : []),
    }),
    getAnimalSex: builder.query<TAnimalSexDto[], string>({
      query: (query) => ({
        url: `${ANIMAL_PATH}/sex`,
        method: 'GET',
        params: { query },
      }),
      transformResponse: (response: TAnimalSexDto[]) =>
        response.map((data: TAnimalSexDto) => ({ ...data, value: data.sex })),
    }),
    getAnimalImages: builder.query<TImageInfoDto[], string>({
      query: (animalId) => ({
        url: `${ANIMAL_PATH}/getAnimalImages`,
        method: 'GET',
        params: { animalId },
      }),
    }),
    getAnimalPedigree: builder.query<(TPedigreeConverted | null)[] | null, string>({
      query: (animalId) => ({
        url: `${ANIMAL_PATH}/pedigree/${animalId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  usePostAnimalMutation,
  useGetAnimalsQuery,
  useGetLittersAnimalsQuery,
  useLazyGetAnimalsQuery,
  useGetAnimalQuery,
  usePatchAnimalMutation,
  useGetAnimalSexQuery,
  usePostAnimalImageMutation,
  usePostAnimalLitterMutation,
} = animalApi;
