import { useCallback } from 'react';
import { clearStorage, setStorageItem, StorageKeys } from '@/shared/utils/storage';
import { HOME_PATHS } from '@/app/navigation';

export const useTokens = () => {
  const updateTokens = useCallback(
    (accessToken: string, refreshToken: string) => {
      setStorageItem([
        { key: StorageKeys.ACCESS_TOKEN, value: accessToken },
        { key: StorageKeys.REFRESH_TOKEN, value: refreshToken },
      ]);
      window.location.href = HOME_PATHS.home;
    },

    [],
  );

  const removeTokens = useCallback(() => {
    clearStorage();
    window.location.href = HOME_PATHS.home;
  }, []);

  return {
    updateTokens,
    removeTokens,
  };
};
