import { ErrorWrapper, LabelWrapper, TLabelProps } from '@/shared/ui';
import { Controller, FieldError } from 'react-hook-form';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { TControllerProps } from '@/shared/common';

type TProps = TextAreaProps & {
  labelProps?: TLabelProps;
  error?: FieldError;
  showErrorMsg?: boolean;
  isBlank?: boolean;
  controllerProps: TControllerProps;
};

const { TextArea: AntTextArea } = Input;

export const TextareaInput = ({ controllerProps, labelProps, ...props }: TProps) => {
  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <LabelWrapper {...labelProps}>
          <ErrorWrapper message={error?.message}>
            <AntTextArea className="font-normal" size="large" status={error && 'error'} {...field} {...props} />
          </ErrorWrapper>
        </LabelWrapper>
      )}
      {...controllerProps}
    />
  );
};
