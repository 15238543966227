import { useGetCurrentUserQuery } from '@/entities/user-controller';
import { TPopupProps } from '@/shared/ui';
import { AnimalCrudPopup } from '@/widgets/animal/animal-crud-popup';
import { ANIMAL_FORM_FIELDS, animalSchema, TAnimalFormProps } from '@/widgets/animal/animal-crud-popup/hooks';

const schema = animalSchema.partial({
  [ANIMAL_FORM_FIELDS.status]: true,
});

export const AddAnimalPopup = ({
  onSubmitFinish,
  ...props
}: TPopupProps & { onSubmitFinish: TAnimalFormProps['onSubmitFinish'] }) => {
  const { data } = useGetCurrentUserQuery();

  const nursery = data?.nurseries[0];

  if (!nursery) {
    return null;
  }

  return (
    <AnimalCrudPopup
      schema={schema}
      onSubmitFinish={onSubmitFinish}
      defaultValues={{
        nursery: { value: nursery.id, label: nursery.nameEn },
        animalRegistrationSystemId: nursery.registrationSystem.id,
        breederName: nursery.account.name,
        breedId: nursery.breeds[0].id,
      }}
      speciesId={nursery.species.id}
      {...props}
    />
  );
};
