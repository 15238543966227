import { useTypedSelector, selectIsBreeder, selectCurrentAccountId } from '@/app/store';
import { ROUTE } from '@/app/navigation/constants';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { HomeIcon, More, NurseryIcon, MarketplaceIcon, ShopIcon } from '@/app/icons';
import { Body2 } from '@/shared/components';
import { useModal, useMedia } from '@/shared/hooks';
import { device } from '@/app/theme/device';
import { useLocation } from 'react-router';
import { HOME_PATHS, MARKETPLACE_PATHS } from '@/app/navigation';
import { SHOP_PATHS } from '@/app/navigation/shop';

const openStyles = css`
  width: 100% !important;

  div {
    opacity: 1;
    margin-left: 12px;
    visibility: visible;
    color: ${({ theme }) => theme.palette.neutral[8]};
  }
`;

const NavItemStyles = css`
  border-radius: 8px;
  width: 48px;
  height: 48px;
  background-color: ${({ theme }) => theme.palette.neutral['8alpha10']};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 12px;
  z-index: 100;

  transition: all 0.2s ease-out;

  div {
    width: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-out;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.neutral[2]};
    ${openStyles};
  }

  svg {
    color: ${({ theme }) => theme.palette.neutral[8]};
  }

  @media screen and ${device.tablet} {
    width: 32px;
    height: 32px;
    padding-left: 6px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const IconWrapper = styled(Link)<{ $isOpen: boolean; $isActive: boolean; $isMobile: boolean }>`
  ${NavItemStyles};
  ${({ $isOpen, $isMobile }) => ($isOpen || $isMobile) && openStyles};
  ${({ $isActive }) =>
    $isActive &&
    css`
      background: ${({ theme }) => theme.palette.neutral[2]};
    `}
`;

const ExpandWrapper = styled.div<{ $isOpen: boolean }>`
  ${NavItemStyles};
  ${({ $isOpen }) => $isOpen && openStyles} };
`;

const MoreIcon = styled(More)<{ $isOpen: boolean }>`
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(180deg);
    `};
`;

export const useNavMenu = () => {
  const isBreeder = useTypedSelector(selectIsBreeder);
  const account = useTypedSelector(selectCurrentAccountId);
  const { pathname } = useLocation();

  const { isMobile } = useMedia();

  const { isOpen, open, close } = useModal();

  const handleExpand = () => {
    isOpen ? close() : open();
  };

  const menu = useMemo(
    () => [
      {
        to: HOME_PATHS.home,
        label: 'Главная',
        isVisible: isMobile,
        isActive: pathname === HOME_PATHS.home,
        icon: <HomeIcon />,
      },
      {
        to: ROUTE.NURSERY,
        label: 'Питомник',
        isVisible: isBreeder,
        icon: <NurseryIcon />,
        isActive: pathname === ROUTE.NURSERY,
      },
      {
        to: MARKETPLACE_PATHS.marketplace,
        label: 'Маркетплейс',
        isVisible: true,
        icon: <MarketplaceIcon />,
        isActive: pathname.includes(MARKETPLACE_PATHS.marketplace),
      },
      {
        to: SHOP_PATHS.shop,
        label: 'Магазин',
        isVisible: !!account,
        icon: <ShopIcon />,
        isActive: pathname.includes(SHOP_PATHS.shop),
      },
    ],
    [account, pathname, isBreeder, isMobile],
  );

  const navMenuItems = useMemo(
    () =>
      menu
        .filter(({ isVisible }) => isVisible)
        .map(({ label, to, icon, isActive }) => (
          <IconWrapper
            className="nav-item"
            $isMobile={isMobile}
            $isOpen={isOpen}
            $isActive={isActive}
            key={label}
            to={to}
          >
            {icon}
            <Body2 weight="SB">{label}</Body2>
          </IconWrapper>
        )),
    [isMobile, isOpen, menu],
  );

  const ExpandItem = () => (
    <ExpandWrapper $isOpen={isOpen} onClick={handleExpand}>
      <MoreIcon $isOpen={isOpen} />
      <Body2 weight="SB">{isOpen ? 'Свернуть' : 'Развернуть'}</Body2>
    </ExpandWrapper>
  );

  return { navMenuItems, ExpandItem, isExpanded: isOpen };
};
