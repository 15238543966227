import { Modal, ModalProps } from 'antd';

import './styles.scss';
import { Button } from '@/shared/ui';
import { ModalCloseIcon } from '@/app/icons';
import cn from 'classnames';

const MODAL_SIZE = {
  LARGE: 1214,
  MEDIUM: 785,
  SMALL: 485,
} as const;

export type TPopupProps = Omit<ModalProps, 'onCancel'> & {
  onCancel: () => void;
  size?: keyof typeof MODAL_SIZE;
  withoutFooterDivider?: boolean;
};

export const Popup = ({
  cancelText,
  okText,
  cancelButtonProps,
  okButtonProps,
  size = 'SMALL',
  onCancel,
  width,
  onOk,
  className,
  withoutFooterDivider = false,
  ...props
}: TPopupProps) => {
  return (
    <Modal
      centered
      onCancel={onCancel}
      width={width || MODAL_SIZE[size]}
      destroyOnClose
      className={cn('custom-popup', className, { withoutFooter: withoutFooterDivider })}
      footer={[
        cancelText && (
          <Button className="sm:max-md:w-full" type="default" key="cancel" onClick={onCancel} {...cancelButtonProps}>
            {cancelText}
          </Button>
        ),
        okText && (
          <Button className="sm:max-md:w-full" key="ok" onClick={onOk} {...okButtonProps}>
            {okText}
          </Button>
        ),
      ]}
      closeIcon={<ModalCloseIcon />}
      {...props}
    />
  );
};
