import { Select, TSelectProps } from '@/shared/ui';
import { TAnimalDto, useGetAnimalsQuery } from '@/entities/animal-controller';
import { comparisonNode, queries } from '@/shared/utils';
import { ANIMAL_SEX } from '@/entities/species-controller';
import { LITTER_FORM_FIELDS, TLitterSchema } from '@/widgets/litters';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { useFormContext } from 'react-hook-form';
import { convertMotherFatherResponse } from '@/features/litters/select/helpers';

export const LittersMotherSelect = ({
  accountId,
  handleChangeParent,
  ...props
}: TSelectProps<TAnimalDto & DefaultOptionType> & {
  accountId: string;
  handleChangeParent: () => void;
}) => {
  const {
    setValue,
    formState: { defaultValues },
  } = useFormContext<TLitterSchema>();

  const query = queries.and([
    comparisonNode.equal('owner.id', accountId),
    comparisonNode.equal('speciesSex.sex', ANIMAL_SEX.FEMALE),
  ]);

  const { data = [], isFetching } = useGetAnimalsQuery(
    { query },
    {
      selectFromResult: ({ data, ...rest }) => ({
        data: convertMotherFatherResponse(data, defaultValues?.birthDate),
        ...rest,
      }),
    },
  );

  const onChange = (_: string, option: TAnimalDto | TAnimalDto[]) => {
    if (!Array.isArray(option)) {
      setValue(LITTER_FORM_FIELDS.mother, option, { shouldValidate: true });
      handleChangeParent();
    }
  };

  return <Select onChange={onChange} options={data} loading={isFetching} {...props} />;
};
