import { Flex } from 'antd';
import { ProjectContacts, ProjectLinks, ProjectPayments, ProjectRegistry } from '@/features/project-info';
import cn from 'classnames';

export const ProjectInfoPage = ({ contentWidth }: { contentWidth: string }) => (
  <div className={cn('bg-neutral7 py-[40px] px-xs mx-[-32px]')}>
    <Flex justify="space-between" className={`w-full max-w-[${contentWidth}] mx-auto`}>
      <ProjectContacts />
      <ProjectRegistry />
      <Flex vertical gap={8}>
        <ProjectPayments />
        <ProjectLinks />
      </Flex>
    </Flex>
  </div>
);
