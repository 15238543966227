import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const GLOBAL_MODALS_SLICE_NAME = 'globalModals';

export enum MODALS {
  EMAIL_CONFIRMATION,
  CHANGES_SAVED,
  ADD_ACCOUNT_NOTIFICATION,
  ADD_NURSERY_NOTIFICATION,
  ADD_PETCARD_NOTIFICATION,
  EDIT_ACCOUNT_NOTIFICATION,
  ADD_ANIMAL,
  ADD_LITTER_NOTIFICATION,
  EMAIL_RESEND,
  PASSWORD_RESET,
  AUTH,
  POST_CREATED,
  POST_CHANGED,
  POST_DELETED,
  SIGN_IN,
  REGISTRATION,
  GOOD_PUBLISHED,
  EDIT_GOODS_NOTIFICATION,
  DELETE_GOODS_NOTIFICATION,
}

const initialState: { modal: MODALS | null; text: string | null } = {
  modal: null,
  text: null,
};

const globalModalsSlice = createSlice({
  name: GLOBAL_MODALS_SLICE_NAME,
  initialState,
  reducers: {
    setGlobalModal: (state, { payload }: PayloadAction<MODALS | { modal: MODALS; text: string }>) => {
      if (typeof payload === 'object') {
        state.modal = payload.modal;
        state.text = payload.text;
      } else {
        state.modal = payload;
      }
    },
    resetGlobalModal: (state) => {
      state.modal = null;
      state.text = null;
    },
  },
});

export const { setGlobalModal, resetGlobalModal } = globalModalsSlice.actions;
export const globalModalsReducer = globalModalsSlice.reducer;
