import { Controller } from 'react-hook-form';
import { InputComponent, TInputProps } from '@/shared/ui';

type TProps = TInputProps & {
  decimalLimit?: number;
};

export const NumberInput = ({ controllerProps, decimalLimit, ...props }: TProps) => {
  return (
    <Controller
      render={({ field: { value = '', onChange }, fieldState: { error } }) => (
        <InputComponent
          onChange={(e) => {
            const { value } = e.target;
            const checkValue = value.replace('.', ',');
            const regex = decimalLimit ? new RegExp(`^\\d*(,\\d{0,${decimalLimit}})?$`) : /^\d*$/;

            if (!regex.test(checkValue) || checkValue.startsWith(',')) {
              return;
            }

            onChange(checkValue);
          }}
          onBlur={() => {
            if (value && value.at(-1) === ',') {
              onChange(value.slice(0, -1));
            }
          }}
          error={error}
          value={value}
          {...props}
        />
      )}
      {...controllerProps}
    />
  );
};
