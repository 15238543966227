import { useGetLittersQuery } from '@/entities/litters-controller';
import { Body2, Button } from '@/shared/components';
import { useMedia, useModal } from '@/shared/hooks';
import { queries } from '@/shared/utils';
import { Flex } from 'antd';
import { InfinityContainer, List } from '@/shared/ui';
import { useState } from 'react';
import { LitterCard } from '@/features/litters';
import { AddSquare, GridViewSwitcherIcon, ListViewSwitcherIcon, Loader } from '@/app/icons';
import { LitterPopup } from '@/widgets/litters';

const ADD_BUTTON_LABEL = 'Добавить помёт';
const INFO_SECTION_TEXT = 'У вас еще нет рожденных питомцев.';

type TProps = {
  nurseryId: number;
};

export const Litters = ({ nurseryId }: TProps) => {
  const [page, setPage] = useState<number>(1);
  const [isList, setIsList] = useState(false);
  const { isOpen, open, close } = useModal(false);

  const litterQuery = queries.equal('nursery.id', nurseryId);

  const { data, isFetching, isLoading } = useGetLittersQuery({
    query: litterQuery,
    size: 9,
    page,
  });

  const totalElements = data?.totalElements;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Flex vertical gap={16}>
      {!totalElements && <Body2>{INFO_SECTION_TEXT}</Body2>}
      {!totalElements && (
        <Button type="default" onClick={open}>
          {ADD_BUTTON_LABEL}
        </Button>
      )}
      {!!totalElements && (
        <Flex vertical={false} gap={12} justify="flex-end">
          {isList ? (
            <GridViewSwitcherIcon className="cursor-pointer" onClick={() => setIsList(false)} />
          ) : (
            <ListViewSwitcherIcon className="cursor-pointer" onClick={() => setIsList(true)} />
          )}
          <AddSquare className="cursor-pointer" onClick={open} />
        </Flex>
      )}
      <InfinityContainer
        data={data}
        isFetching={isFetching}
        setPage={setPage}
        renderedItem={(content) => (
          <List
            grid={{ gutter: 16, column: isList ? 1 : 3 }}
            itemLayout={isList ? 'vertical' : 'horizontal'}
            dataSource={content}
            customItem={(litter) => <LitterCard key={litter.id} litter={litter} isList={isList} />}
          />
        )}
      />
      {isOpen && <LitterPopup open={isOpen} onCancel={close} />}
    </Flex>
  );
};
