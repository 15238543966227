import React from 'react';
import { useHistory, useMedia } from '@/shared/hooks';
import { MARKETPLACE_PATHS } from '@/app/navigation';
import { TGoodDto, useGetGoodsRecommendationQuery } from '@/entities/goods-controller';
import { List } from '@/shared/ui';
import { GoodCard } from '@/features/marketplace/goods-card';

export const MarketplaceAuthorizedGoodsList = () => {
  const { isDesktop } = useMedia();
  const slidesToShow = isDesktop ? 5 : 2;
  const navigate = useHistory();
  const onCardClick = ({ category, id }: TGoodDto) => {
    navigate(`${MARKETPLACE_PATHS.catalog}/${category.id}/goods/${id}`);
  };
  const { data: recommendationGoodsData } = useGetGoodsRecommendationQuery();

  return (
    <List
      grid={{ gutter: 16, column: slidesToShow }}
      dataSource={recommendationGoodsData}
      customItem={(item) => <GoodCard onClick={onCardClick} good={item} />}
    />
  );
};
