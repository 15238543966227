import dayjs from 'dayjs';
import { TAnimalDto } from '@/entities/animal-controller';
import { TSelectOption } from '@/shared/common';

export const convertMotherFatherResponse = (data?: (TAnimalDto & TSelectOption)[], liiterDateBirth?: string) =>
  data?.filter(({ dateBirth }) => {
    if (!dateBirth) {
      return false;
    }
    console.log(liiterDateBirth);
    console.log(dateBirth);
    const currentDate = liiterDateBirth ? dayjs(liiterDateBirth).startOf('day') : dayjs().startOf('day');
    const diffDate = dayjs(dateBirth).startOf('day');

    const diff = currentDate.diff(diffDate, 'day');
    return diff >= 180;
  });
