import { Datepicker } from '@/shared/ui';
import dayjs, { Dayjs } from 'dayjs';
import { LITTER_FORM_FIELDS, TLitterSchema } from '@/widgets/litters';
import { useFormContext } from 'react-hook-form';

export const BirthMatingDatepickers = ({ isEditMode }: { isEditMode?: boolean }) => {
  const { watch } = useFormContext<TLitterSchema>();

  const [motherWatch, fatherWatch, birthDateWatch, matingDateWatch] = watch([
    LITTER_FORM_FIELDS.mother,
    LITTER_FORM_FIELDS.father,
    LITTER_FORM_FIELDS.birthDate,
    LITTER_FORM_FIELDS.matingDate,
  ]);

  const youngestParentDateBirth = dayjs(motherWatch?.dateBirth).isAfter(dayjs(fatherWatch?.dateBirth))
    ? fatherWatch?.dateBirth
    : motherWatch?.dateBirth;

  const disabledBirthDay = (current: Dayjs) =>
    current.isAfter(dayjs()) || current.isBefore(dayjs(youngestParentDateBirth).add(180, 'day'));

  const disabledMatingDay = (current: Dayjs) =>
    current.isAfter(dayjs()) ||
    current.isBefore(dayjs(birthDateWatch).subtract(80, 'day')) ||
    current.isAfter(dayjs(birthDateWatch).subtract(50, 'day'));

  const isBirthDateDisabled = !motherWatch || !fatherWatch;
  const isMatingDateDisabled = isEditMode ? false : isBirthDateDisabled || !birthDateWatch;

  return (
    <>
      <Datepicker
        labelProps={{ label: 'Дата рождения:' }}
        controllerProps={{ name: LITTER_FORM_FIELDS.birthDate }}
        disabledDate={disabledBirthDay}
        maxDate={dayjs()}
        showErrorMsg={false}
        disabled={isBirthDateDisabled}
      />
      <Datepicker
        labelProps={{ label: 'Дата вязки:' }}
        controllerProps={{ name: LITTER_FORM_FIELDS.matingDate }}
        disabledDate={disabledMatingDay}
        disabled={isMatingDateDisabled}
        defaultPickerValue={matingDateWatch ? dayjs(matingDateWatch) : dayjs(birthDateWatch).subtract(50, 'day')}
      />
    </>
  );
};
