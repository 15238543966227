import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentAccountId } from '@/app/store';
import { MarketplaceGoodsList, MarketplaceAuthorizedGoodsList } from '@/features/marketplace';

export const MarketplaceRecommendations = () => {
  const accountId = useSelector(selectCurrentAccountId);

  return accountId ? <MarketplaceAuthorizedGoodsList /> : <MarketplaceGoodsList />;
};
